import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Table, Tooltip, Button, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCustomerDetails, getLoginEvents } from 'app/entities/customer/customer.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-select-search/style.css';
import './adminProfile.css';
import { downloadExcel } from 'react-export-table-to-excel';
import { StatusData, downloadDocument, getAdminCompleteEntities, getAdminPendingEntities, getAdminRejectEntities, getIbkrAccountLedger, getNeedInfo, getPendingTaskEntity, ibkraccountstatus, postAdminApproval, sendInfoMail, sendRejectedMail } from './admin.reduce';
import { PencilFill ,ArrowClockwise} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import CommonToast from 'app/modules/components/CommonToast';
import { saveInitialPath } from 'app/modules/account/register/register.reducer';
import Lottie from 'lottie-react';
import animationData from "../../../content/lottie/upload_loader.json"
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const AdminProfile = () => {
  const navigate = useNavigate()
  const signatureData = useAppSelector(state => state.accounts.signatureData);
  const accountId = useAppSelector(state => state.accounts?.entity?.accountId)
    || signatureData?.statement_of_suitability?.account_id;

  const [outSorcingData, setrOutSourcingData] = useState('')
  const [activeTab, setActiveTab] = useState('swAccount');
  const dispatch = useAppDispatch();
  const [searchData, setSearchData] = useState('');
  const [accountTypeFilter, setAccountTypeFilter] = useState('');

  const [modal, setModal] = useState(false);

  const customerDetails = useAppSelector(state => state.customer.entities);
  const loginEvents = useAppSelector(state => state.customer.loginEvents);
  const accountsList = useAppSelector(state => state.accounts.entities.accountId);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [name, setName] = useState()
  const [lastName, setLastName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [depositstartDatecomplete, setDepositStartDatecomplete] = useState('');
  const [depositEndDatecomplete, setDepositEndDatecomplete] = useState('');
  const [withdrawalstartDatecomplete, setWithdrawalStartDatecomplete] = useState('');
  const [withdrawalEndDatecomplete, setWithdrawalEndDatecomplete] = useState('');
  const [startDatecomplete, setStartDatecomplete] = useState(new Date().toISOString().split('T')[0]);
  const [endDatecomplete, setEndDatecomplete] = useState(new Date().toISOString().split('T')[0]);
  const [startDatereject, setStartDatereject] = useState('');
  const [endDatereject, setEndDaterejecte] = useState('');
  const [PendingAccounts, setPendingAccounts] = useState([]);
  const [withdrawalAccounts, setWithdrawalAccounts] = useState([]);
  const [depositAccounts, setDepositAccounts] = useState([]);


  const [PendingTask, setPendingTask] = useState([]);
  const [loaading, setLoaading] = useState(false);
  const [pendingCurrentPage, setPendingCurrentPage] = useState(0);
  const [pendingTotalPages, setPendingTotalPages] = useState(0);
  const [CompletedAccounts, setCompletedAccounts] = useState([]);
  const [completedCurrentPage, setCompletedCurrentPage] = useState(0);
  const [completedTotalPages, setCompletedTotalPages] = useState(0);
  const [rejectedAccounts, setRejectedAccounts] = useState([]);
  const [rejectedCurrentPage, setRejectedCurrentPage] = useState(0);
  const [rejectedTotalPages, setRejectedTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [openOutSource, setOpenOutSource] = useState(false);
  const [selectedItem, setSelectedItem] = useState("")
  const [accountData, setAccountData] = useState([]);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [outSourcedAccountId, setOutSourcedAccountId] = useState()
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [completedstatus, setCompletedStatus] = useState(false);


  const [showAll, setShowAll] = useState(false);

  //
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {

      // navigate('/accounts');
      if (window.location.pathname === '/accounts') {
        navigate('/accounts')
      } else {
        if (window.location.pathname === '/company') {
          navigate('/company')
        } else if (window.location.pathname === '/dashboard') {
          navigate('/dashboard')
        } else if (window.location.pathname === '/admin-profile') {
          navigate('/admin-profile')
        }
      }
    } else {
      dispatch(saveInitialPath(window.location.pathname))
      navigate('/login');
    }
  }, []);
  useEffect(() => {
    if (accountId) {
    } else {
    }
  }, [accountId]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    const params = new URLSearchParams(location.search);
    params.set('tab', tabName);
    navigate({ search: params.toString() }, { replace: true });
  };

  const handleToggleShow = () => {
    setShowAll(prevShowAll => !prevShowAll);
  };
  const [selectedOptions, setSelectedOptions] = useState<StatusData>({ accountId: "", status: "", description: "" });
  const toggle = () => {
    setOpen(value5 => !value5);
  };
  const toggleOutSource = (accountId) => {
    setOutSourcedAccountId(accountId)
    setOpenOutSource(true);
  };
  const handletoggleOutSourceClose = () => {
    setOpenOutSource(false);

  };
  useEffect(() => {
    if (customerDetails?.length > 0) {
      const customer = customerDetails[0];
      setName(customer?.firstName || '');
      setLastName(customer?.lastName || '');
      setCustomerId(customer?.customerId || '');
      setEmail(customer?.email || '');
      setPhone(customer?.phone || '');
    }
  }, [customerDetails]);

  useEffect(() => {
    dispatch(getCustomerDetails());
  }, [dispatch]);
  const handleViewApplication = (accountId) => {
    navigate(`/view-doc/${accountId}`);
  };

  useEffect(() => {
    if (accountId) {
      dispatch(getNeedInfo({ accountId }))
      .then((response) => {
        setAccountData(response?.payload)
      })
      .catch((error) => {
        console.error('Error fetching need info:', error);
      });
    }
  }, []);


  const handleGetAccountStatus = async (page: number) => {
    try {
      const response: any = await dispatch(
        getAdminPendingEntities({
          startDate,
          endDate,
          page,
          size: 10,
          status: 'PENDING',
          name:searchName
        })
      );
  
      console.log('response admin pending:', response?.payload?.data?.content);
      setPendingAccounts(response?.payload?.data?.content);
      setPendingTotalPages(response?.payload?.data?.totalPages);
      setPendingCurrentPage(page);
    } catch (error) {
      console.error('Error fetching account status:', error);
    }
  };
  
  const handleDownloadDocument = (fileName: string, accountNumber: string) => {

    const body = {
      fileName,
      accountNumber
    }
    dispatch(downloadDocument(body))
      .then(response => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-error
        const url = window.URL.createObjectURL(response?.payload?.data as Blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        console.error(`Error downloading document ${fileName}:`, error);
      });
  };


  useEffect(() => {
      
      handleGetAccountStatus(pendingCurrentPage);
    
  }, [startDate, endDate]);

  const handleGetAccountCompletedStatus = (page = 1) => {
    if (startDatecomplete && endDatecomplete) {
      dispatch(getAdminCompleteEntities({ startDate: startDatecomplete, endDate: endDatecomplete, page, size: 5, status: 'APPROVED',name:"" })).then((response: any) => {
        setCompletedAccounts(response?.payload?.data?.content);
        setCompletedTotalPages(response?.payload?.data?.totalPages);
        setCompletedCurrentPage(page);
      });
    }
  };

  useEffect(() => {
    if (startDatecomplete && endDatecomplete) {
      handleGetAccountCompletedStatus(completedCurrentPage);
    }
  }, [startDatecomplete, endDatecomplete]);

  const handleGetAccountRejecteddStatus = (page = 1) => {
      dispatch(getAdminRejectEntities({ startDate: startDatereject, endDate: endDatereject, page, size: 5, status: 'IBKR_REJECTED',name:searchName })).then((response: any) => {
        setRejectedAccounts(response?.payload?.data?.content);
        setRejectedTotalPages(response?.payload?.data?.totalPages);
        setRejectedCurrentPage(page);
      });
    
  };

  useEffect(() => {
      handleGetAccountRejecteddStatus(rejectedCurrentPage);
    
  }, [startDatereject, endDatereject]);

  
  const handleGetWithdrawalList = () => {
    
      if (activeTab == 'WithdrawalList'){
        dispatch(getIbkrAccountLedger({ startDate: `${withdrawalstartDatecomplete}` , endDate: `${withdrawalEndDatecomplete}`, status: 'CREATED',transactionType: 'DR',name:searchName })).then((response: any) => {
          setWithdrawalAccounts(response?.payload?.data);
        });
      }
      else{
        
        dispatch(getIbkrAccountLedger({ startDate: withdrawalstartDatecomplete , endDate: withdrawalEndDatecomplete, status: 'CREATED',transactionType: 'DR',name:searchName })).then((response: any) => {
          setWithdrawalAccounts(response?.payload?.data);
          
        });
      }
        
    
  };
  useEffect(() => {
    if (activeTab === 'WithdrawalList') {
      handleGetWithdrawalList();
    }
  }, [activeTab]); // Dependency on activeTab to ensure it runs when tab changes to WithdrawalList


  useEffect(() => {
      handleGetWithdrawalList();
    
  }, [withdrawalstartDatecomplete, withdrawalEndDatecomplete]);

  const handleGetDepositList = () => {
    
      dispatch(getIbkrAccountLedger({ startDate: `${depositstartDatecomplete}`, endDate: `${depositEndDatecomplete}`, status: 'CREATED',transactionType: 'CR',name:searchName })).then((response: any) => {
         setDepositAccounts(response?.payload?.data);
         
      });
    
  };

  useEffect(() => {
      handleGetDepositList();
    
  }, [depositstartDatecomplete, depositEndDatecomplete]);

  const handleGetLoginEvents = () => {
    dispatch(
      getLoginEvents({
        startDate,
        endDate,
        page: 0,
        size: 20,
        sortedBy: true,
        loginstatus: 1,
      }),
    );
  };

  const handleGenerateReport = () => {
    if (loginEvents && loginEvents.content) {
      const header = ['Customer Id', 'Login Status', 'Event', 'Role', 'Username', 'Created At', 'Updated at'];

      const body = loginEvents.content.map(event => [
        event.customer_id,
        event.login_status,
        event.event,
        event.role,
        event.username,
        event.created_at,
        event.updated_at,
      ]);

      downloadExcel({
        fileName: 'Audit Trail Report',
        sheet: 'Audit Trail Report',
        tablePayload: {
          header,
          body,
        },
      });
      toggleModal();
      setStartDate('');
      setEndDate('');
    } else {
      console.error('Login events data is undefined');
    }
  };

  const handleWithdrawalReport = () => {
    if (withdrawalAccounts ) {
      const header = ['IBKR AccountNumber', 'Debit Amount', 'Status', 'CreatedAtGMT'];

      const body = withdrawalAccounts.map(event => [
        event.ibkrAccountNumber,
        event.debit,
        event.debitCreditFlag === 'DR' ? 'Debit' : event.debitCreditFlag,
        event.createdAtGMT,
      ]);

      downloadExcel({
        fileName: 'IBKR Debited Account Report',
        sheet: 'IBKR Debited Account Report',
        tablePayload: {
          header,
          body,
        },
      });
      toggleModal();
      setWithdrawalStartDatecomplete('');
      setWithdrawalEndDatecomplete('');
    } else {
      console.error('IBKR Debited Account data is undefined');
    }
  };

  const handleDepositReport = () => {
    if (depositAccounts ) {
      const header = ['IBKR AccountNumber', 'Credit Amount', 'Status', 'CreatedAtGMT'];

      const body = depositAccounts.map(event => [
        event.ibkrAccountNumber,
        event.credit,
        event.debitCreditFlag === 'CR' ? 'Credit' : event.debitCreditFlag,
        event.createdAtGMT,
      ]);

      downloadExcel({
        fileName: 'IBKR Credited Account Report',
        sheet: 'IBKR Credited Account Report',
        tablePayload: {
          header,
          body,
        },
      });
      toggleModal();
      setDepositStartDatecomplete('');
      setDepositEndDatecomplete('');
    } else {
      console.error('IBKR Credited Account data is undefined');
    }
  };

  const [openRefreshToast, setOpenRefreshToast] = useState(false);
  const [openConfirmationToast, setOpenConfirmationToast] = useState(false);


  const handleRefresh = () => {
    setLoading(true);
    setOpenRefreshToast(true);

    handleGetAccountStatus(0);

    setTimeout(() => {
      setLoading(false);
      setOpenRefreshToast(false);
    }, 1000);
  };
  

  const toggleModal = () => setModal(!modal);

  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);
  const toggleTooltip2 = () => setTooltipOpen2(!tooltipOpen2);
  const toggleTooltip3 = () => setTooltipOpen3(!tooltipOpen3);

  const filterData = data => {
    return data.filter(
      item =>
        item.accountType.toLowerCase().includes(searchData.toLowerCase()) &&
        (accountTypeFilter === '' || item.accountType.toLowerCase() === accountTypeFilter.toLowerCase()),
    );
  };

  const tableData = [
    { name: 'Mark', accountType: 'individual', email: 'mark@gmail.com', contact: '+353999098988' },
    { name: 'Jacob', accountType: 'joint', email: 'jacob@gmail.com', contact: '+353999098988' },
    { name: 'Larry', accountType: 'Company', email: 'larry@twitter', contact: '+353999098988' },
  ];

  const [editMode, setEditMode] = useState({});
  const [countdown, setCountdown] = useState(10);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [emailnew, setEmailNew] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleApproval = (accountId, selectedStatus) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      accountId,
      status: selectedStatus,
      description: selectedStatus === 'NEED_MORE_INFO' ? prevOptions.description : ''
    }));
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [accountId]: false,
    }));
    setOpen(true);
  };
  const handleIbkrApproval = (id, value) => {
    if (!id) {
      console.error('Invalid accountId');
      return;
    }
    // Dispatch the thunk with the correct id and value (status)
    dispatch(ibkraccountstatus({ id, status: value }));
    setCompletedStatus(true);
  };

  const handleModalConfirm = async () => {
    // setIsCountingDown(true);
    setLoading(true);
    setError(null);


    try {
      const data = {
        accountId: selectedOptions?.accountId,
        status: selectedOptions?.status,
        description: selectedOptions?.status === 'NEED_MORE_INFO' ? selectedOptions.description : undefined,
      };

      const res = await dispatch(postAdminApproval(data));
      setLoading(false);
      setOpen(false);
      if (res?.payload) {
        if (res?.payload?.message?.startsWith("IBKR BadRequest ")) {
          let customMessage = res?.payload?.message?.replace("IBKR BadRequest ", "");
          if (customMessage?.startsWith("[")) {
            customMessage = new Array(customMessage).join(",");
          }
        }
      }



      if (res?.payload === "Application Status APPROVED") {
        setToastMessage((prev) => 'Application Approved');
        setToastType((prev) => 'success');
        setToastVisible((prev) => true);
      } else if (typeof (res?.payload) === 'string' && String(res?.payload)?.trim() === "Application Status NEED_MORE_INFO") {
        setToastMessage((prev) => 'Email send Successfully');
        setToastType((prev) => 'success');
        setToastVisible((prev) => true);
      } else {
        setToastMessage((prev) => res?.payload?.errors?.[0] || "Request timed out,Please try approval again");
        setToastType((prev) => 'error');
        setToastVisible((prev) => true);
        // handleGetAccountStatus(0);

      }

      handleGetAccountStatus(0);
      setSelectedOptions({ accountId: "", status: "", description: "" });
      setEditMode({});

      setUpdateSuccess(true);
    } catch (error) {
      setLoading(false);
      setError(error);

    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setIsCountingDown(false);
    setCountdown(10);
  };


  useEffect(() => {
    if (!isCountingDown) {
      setCountdown(10);
    }
  }, [isCountingDown]);

  const toggleSelectBox = (_accountId: string) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [_accountId]: !prevEditMode[_accountId]
    }));
  };
  const optionsString = Array.isArray(selectedOptions) ? selectedOptions.join(', ') : selectedOptions;

  const handleSendRejectedMail = async (accountId: any) => {

    try {
      const body = {
        account_number: accountId,
        description: "",
      };

      const res = await dispatch(sendRejectedMail(body));


      if (res?.payload?.detail) {
        setToastMessage(res?.payload?.detail);
        setToastType('error');
        setToastVisible(true);


      } else if (res?.payload) {
        setToastMessage(typeof res?.payload == 'string' ? res?.payload : '');
        setToastType('success');
        setToastVisible(true);
      }
    } catch (error) {

      setToastMessage("Failed to send mail");
      setToastType('error');
      setToastVisible(true);
    }
  };
  const handleSendRejectedReasonMail = async () => {

    try {
      const body = {
        account_number: outSourcedAccountId,
        description: outSorcingData,
      };

      const res = await dispatch(sendRejectedMail(body));

      setOpenOutSource(false)
      if (res?.payload) {
        setToastMessage(res?.payload);
        setToastType('success');
        setToastVisible(true);

      } else {
        setToastMessage(res?.payload?.detail);
        setToastType('error');
        setToastVisible(true);
      }
    } catch (error) {
      setToastMessage("Failed to send mail");
      setToastType('error');
      setToastVisible(true);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      to_mail: emailnew,
      subject: subject,
      body: message,
    };

    try {
      const result = await dispatch(sendInfoMail({ data: formData })).unwrap();
      if (result.status==true) {
        setShowSuccessModal(true);
      } else {
        alert(`Failed to send email: ${result.message}`);
      }
    } catch (error) {
      alert(`Failed to send email. Error: ${error.message}`);
    }
  };

  const handleGetPendingTask = () => {
    setLoaading(true); // Start loading
    dispatch(getPendingTaskEntity())
      .then((response: any) => {
        setPendingTask(response?.payload?.data);
        handleGetAccountStatus(pendingCurrentPage);
      })
      .catch((error) => {
        console.error('Error fetching pending tasks:', error);
      })
      .finally(() => {
        setLoaading(false); // Stop loading
      });
  };

  const handleSearch = () => {
    handleGetAccountStatus(pendingCurrentPage);
  };
  const handleSearchRejected = () => {
    handleGetAccountRejecteddStatus(pendingCurrentPage);
  };
  const handleSearchWithdraw = () => {
    handleGetWithdrawalList();
  };
  const handleSearchDeposit = () => {
    handleGetDepositList();
  };


  return (
    <div className="container-fluid">

      <CommonToast
        visible={toastVisible}
        onClose={() => { setToastVisible(false) }}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}


      />
      <br />
      <Row className="pt-5 pb-5 mt-10">
        <br />
        <nav className="col-md-3 col-lg-2 bg-white sidebar position-sticky mt-6 profile-tab-one">
          <div
            className={`nav m-s-2 flex-column nav-pills mt-2 ${activeTab === 'swAccount' ? 'profile-active-new' : ''}`}
            onClick={() => {setActiveTab('swAccount'); setSearchName('');
            }}
          >
            <a className="nav-link user-profile-text new-profile-color nio">Audit Trail Reports</a>
          </div>
          <div
            className={`nav flex-column nav-pills ${activeTab === 'customeAccountDetailspending' ? 'profile-active-new' : ''}`}
            onClick={() => {setActiveTab('customeAccountDetailspending');    setSearchName('');
                        }            }
          >
            <a className="nav-link user-profile-text new-profile-color nio">Applications Pending For Approval</a>
          </div>

          <div
            className={`nav flex-column nav-pills ${activeTab === 'customeAccountDetailscomplete' ? 'profile-active-new' : ''}`}
            onClick={() => {setActiveTab('customeAccountDetailscomplete')  ;  setSearchName('');
            }}
          >
            <a className="nav-link user-profile-text new-profile-color nio">Customer Active Applications </a>
          </div>
          <div
            className={`nav flex-column nav-pills ${activeTab === 'ibkrRejectedDetails' ? 'profile-active-new' : ''}`}
            onClick={() =>{setActiveTab('ibkrRejectedDetails');    setSearchName('');
                        }            }
          >
            <a className="nav-link user-profile-text new-profile-color nio">IBKR Rejected Applications </a>
          </div>
          <div
            className={`nav flex-column nav-pills ${activeTab === 'needInfo' ? 'profile-active-new' : ''}`}
            onClick={() =>{setActiveTab('needInfo');    setSearchName('');}
}
          >
            <a className="nav-link user-profile-text new-profile-color nio">Email Notification </a>
          </div>
          <div
            className={`nav flex-column nav-pills ${activeTab === 'WithdrawalList' ? 'profile-active-new' : ''}`}
            onClick={() => {setActiveTab('WithdrawalList');    setSearchName('');
                        }            }
          >
            <a className="nav-link user-profile-text new-profile-color nio">Withdrawal List </a>
          </div>
          <div
            className={`nav flex-column nav-pills ${activeTab === 'DepositList' ? 'profile-active-new' : ''}`}
            onClick={() => {setActiveTab('DepositList');    setSearchName('');
                        }            }
          >
            <a className="nav-link user-profile-text new-profile-color nio">Deposit List </a>
          </div>
        </nav>
        <main className="col-md-9 ms-sm-auto col-lg-10  px-md-4 px-0">
          <div className="card bg-white mt-2 mt-md-0">
            <div className="card-body">
              {activeTab === 'swAccount' && (
                <div className="adminLog">
                  <Button color="primary" onClick={toggleModal}>
                    Download Audit Trail Report
                  </Button>

                  <CommonToast
                    visible={modal}
                    // onClose={toggle7}
                    title={'Audit Trail Report'}
                    needCloseButton={true}
                    type="custom"
                    isAutoClose={false}
                  >
                    <div className="form-group text-start">
                      <label className='ps-1 '>Start Date</label>
                      <Input type="date" name="startDate" className='new-date-box' value={startDate} onChange={e => setStartDate(e.target.value)} />
                    </div>
                    <div className="form-group text-start">
                      <label className='ps-1'>End Date</label>
                      <Input type="date" name="endDate" className='new-date-box' value={endDate} onChange={e => setEndDate(e.target.value)} />
                    </div>
                    <br />
                    <div>
                      {loginEvents && loginEvents.content && (
                        <Button color="primary" onClick={handleGenerateReport}>
                          Download Report
                        </Button>
                      )}
                    </div>
                    <br />
                    <div className='flex justify-content-end align-item-center g-4'></div>
                    <Button color="primary" onClick={handleGetLoginEvents}>
                      Submit
                    </Button>
                    <Button color="secondary" className='ms-2' onClick={toggleModal}>
                      Cancel
                    </Button>
                  </CommonToast>

                </div>
              )}
              {activeTab === 'ibkrAccount' && (
                <div className="user-profile-text1">
                  <div className="pt-3 pb-3">
                    <Input
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Search data..."
                      value={searchData}
                      s
                      className="textfield-style"
                      onChange={e => {
                        setSearchData(e.target.value);
                      }}
                    />
                  </div>
                  <Table className='custom-table-td'>
                    <thead>
                      <tr className='new-form-table-row-two'  style={{backgroundColor:"#fff"}}>
                        <th>Customers</th>
                        <th>Account Type</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className='new-form-table-row-two'>
                      {filterData(tableData).map((item, index) => (
                        <tr className='new-form-table-row-two' key={index}>
                          <td className="table-td">{item?.name}</td>
                          <td className="table-td">{item?.accountType}</td>
                          <td className="table-td">{item?.email}</td>
                          <td className="table-td">{item?.contact}</td>
                          <td className="table-td">
                            <span id={`tooltip1-${index}`}>
                              <FontAwesomeIcon className=" " icon={"envelope"} style={{ fontWeight: '200' }} />
                            </span>
                            <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltip1-${index}`} toggle={toggleTooltip1}>
                              Email
                            </Tooltip>
                            &nbsp;
                            <span id={`tooltip2-${index}`}>
                              <FontAwesomeIcon className=" " icon={"mobile-alt"} style={{ fontWeight: '200' }} />
                            </span>
                            <Tooltip placement="top" isOpen={tooltipOpen2} target={`tooltip2-${index}`} toggle={toggleTooltip2}>
                              Sms
                            </Tooltip>
                            &nbsp;
                            <span id={`tooltip3-${index}`}>
                              <FontAwesomeIcon className=" " icon={"mobile-alt"} style={{ fontWeight: '200' }} />
                            </span>
                            <Tooltip placement="top" isOpen={tooltipOpen3} target={`tooltip3-${index}`} toggle={toggleTooltip3}>
                              Whatsapp
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {activeTab === 'customeAccountDetailspending' && (
                <div className="user-profile-text1">
                <div className="row pb-3">
  <div className="col-md-3 date-picker-container align-content-end">
    <label htmlFor="start-date">Start Date:</label>
    <Input type="date" className='new-date-box' name="startDate" value={startDate} onChange={e => setStartDate(e.target.value)} />
  </div>
  <div className="col-md-3 date-picker-container align-content-end">
    <label htmlFor="end-date">End Date:</label>
    <Input type="date" name="endDate" className='new-date-box' value={endDate} onChange={e => setEndDate(e.target.value)} />
  </div>
  <div className="col-md-6">
    <div className="d-flex align-items-center" style={{marginTop:"24px"}}>
      <div className="d-flex align-items-center">
        <Button color="primary" className="me-2" onClick={() => { handleGetAccountStatus(pendingCurrentPage) }}>
          Submit
        </Button>
        <Button 
          color="primary" 
          className="refresh-admin-profile"
          onClick={handleRefresh}
          disabled={loading}  
        >
          {loading ? (
            <Lottie animationData={animationData} loop autoplay style={{ width: 24, height: 24 }} />
          ) : (
            <ArrowClockwise size={20} style={{ cursor: 'pointer' }} />
          )}
        </Button>
      </div>

      <div className="d-flex align-items-center">
      <Input
    type="text"
    placeholder="Search by name..."
    value={searchName}
    onChange={(e) => setSearchName(e.target.value)}
    className="search-input me-2"
    style={{ 
      width: '200px',
      border: '1px solid #ced4da',  // Standard Bootstrap input border color
      borderRadius: '4px',
      marginLeft:'17px'
    }}
  />
        <Button
          color="primary"
          onClick={handleSearch}
          className="search-button"
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </div>
    </div>

    {/* Refresh-Specific Toast */}
    <CommonToast
      visible={openRefreshToast}
      needCloseButton={true}
      type="custom"
      isAutoClose={false}
    >
      <div className="justify-content-center row font-weight-bold custom-modal-style">
        {loading ? (
          <>
            <p>Please wait...</p>
            <Lottie animationData={animationData} loop autoplay style={{ width: '50px', height: '50px', alignSelf: 'center', justifyContent: 'center' }} />
          </>
        ) : (
          <p>Refresh Complete!</p>
        )}
      </div>
    </CommonToast>
  </div>
</div>
                  {PendingAccounts && (
                    <>
                      <Table responsive className="table-striped table-bordered custom-table-td" >
                        <thead>
                          <tr className='new-form-table-row-two' style={{backgroundColor:"#ffffff"}}>
                            <th>Customer Name</th>
                            <th>Email Id</th>
                            <th>Product</th>
                            <th>Account Type</th>
                            <th>Secondary Applicant Name & Email Id</th>
                            <th>Created Date</th>
                            <th>Approval Status</th>
                            <th>View Documents</th>
                            <th>View Application</th>
                          </tr>
                        </thead>

                        <tbody className='new-form-table-row-two'>
                          {PendingAccounts.map((item) => {

                            return (
                              <tr className='new-form-table-row-two'
                                key={item.id}
                                onMouseEnter={() => setHoveredItemId(item.id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                              >
                                <td className="table-td">
                                  {item.accountHolderType === "Organization" ? (
                                    item.company_details?.company_name
                                  ) : (
                                    <>
                                      {item.accountHolderName}&nbsp;&nbsp;{item.accountHolderSurname}
                                    </>
                                  )}
                                </td>
                                <td className="table-td">
                                  {item.accountHolderType === "Organization" ? (

                                    <>
                                      {item.accountHolderType} has no email address
                                    </>
                                  ) : (
                                    <>
                                      {item?.accountHolderEmail}</>
                                  )}
                                </td>

                                <td className="table-td">{item.accountMasterType}</td>
                                <td className="table-td">{item.accountHolderType}</td>

                                <td className="table-td">
                                  {item.jointAccount && item.joint_details ? (
                                    <>
                                      {item.joint_details.jointfirstname} <br />
                                      {item.joint_details.joint_username}
                                    </>
                                  ) : (
                                    <>
                                      {/* {item.accountHolderType} */}
                                      Not Applicable</>
                                  )}
                                </td>
                                <td className="table-td">{item?.updated_at}</td>
                                <td className="table-td hover-edit">
                                  {editMode[item.id] ? (
                                    <div className="status-dropdown">
                                      <select
                                        // value={item.accountId === null ? 'IN_REVIEW' : item.sw_account_status}
                                        onChange={(e) => handleApproval(item.accountId, e.target.value)}
                                      >
                                        <option> Select </option>
                                        {/* <option value="NEED_MORE_INFO">Need More Info</option> */}
                                        <option value="APPROVED">Approved</option>
                                      </select>
                                    </div>
                                  ) : (
                                    <span>
                                      {selectedItem ? (
                                        <span> {selectedItem}</span>
                                      ) : (
                                        <span>{item.sw_account_status}</span>
                                      )}
                                      {hoveredItemId === item.id && (
                                        <span className="pencil-icon" onClick={() => { toggleSelectBox(item.id) }}>
                                          <PencilFill className="edit-button-style" />
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </td>
                                {/* <td className="table-td">
                                  {accountData?.map((item) => (
                                    <tr key={item.id}>
                                      <td>
                                        {item.status === "NEED_MORE_INFO" && item.description ? (
                                          item.description
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </td> */}
                               
                                <td className="table-td">
                                  {item?.passportDoc && (
                                    <div className='col-12 text-center'>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        {item?.passportDoc && (
                                          <>
                                            <span> Primary Applicant Passport</span></>
                                        )}
                                      </div>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        <Button
                                          color="link"
                                          onClick={() => { handleDownloadDocument(item?.passportDoc, item?.accountId) }}
                                        >
                                          Download
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                  {item?.ppsNumberDoc && (
                                    <div className='col-12 text-center'>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        {item?.ppsNumberDoc && (
                                          <>
                                            <span>Primary Applicant PPS</span></>
                                        )}
                                      </div>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        <Button
                                          color="link"
                                          onClick={() => { handleDownloadDocument(item.ppsNumberDoc, item.accountId) }}
                                        >
                                          Download
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                  {item?.jointAccount && item?.joint_details && (
                                    <>
                                      {item?.joint_details?.joint_passport_doc && (
                                        <div className='col-12 text-center'>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            {item?.joint_details?.joint_passport_doc && (
                                              <span>Secondary Applicant Passport </span>
                                            )}
                                          </div>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            <Button
                                              color="link"
                                              onClick={() => { handleDownloadDocument(item?.joint_details?.joint_passport_doc, item?.accountId) }}
                                            >
                                              Download
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                      {item?.joint_details?.joint_pps_doc && (
                                        <div className='col-12 text-center'>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            {item?.joint_details?.joint_pps_doc && (
                                              <span>Secondary Applicant PPS</span>
                                            )}
                                          </div>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            <Button
                                              color="link"
                                              onClick={() => { handleDownloadDocument(item?.joint_details.joint_pps_doc, item.accountId) }}
                                            >
                                              Download
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {item?.company_details && (
                                    <div className="col-12 text-center" >
                                      {item?.company_details && item?.company_details.proof_of_company_doc && (
                                        <>
                                          <div style={{ backgroundColor: 'transparent', border: 'none', fontSize: "12px" }}>
                                            <span>{item.company_details.proof_of_company_doc?.length > 13 ? (
                                              item?.company_details?.proof_of_company_doc?.slice(0, 13)
                                            ) : (
                                              item?.company_details?.proof_of_company_doc

                                            )}</span>
                                          </div>
                                          <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                            <Button color="link" onClick={() => handleDownloadDocument(item?.company_details.proof_of_company_doc, item?.accountId)}>
                                              Download
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {item.company_details.authorized_person_details && (
                                        item.company_details.authorized_person_details.map((person, personIndex) => (
                                          <div key={personIndex} className="">

                                            {person.proof_of_address && (
                                              <>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <span>Key person&nbsp;{personIndex + 1}&nbsp;{person.proof_of_address.length > 16 ? (
                                                    person.proof_of_address.slice(0, 16)
                                                  ) : (
                                                    person.proof_of_address
                                                  )}</span>
                                                </div>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <Button color="link" onClick={() => handleDownloadDocument(person.proof_of_address, item.accountId)}>
                                                    Download
                                                  </Button>
                                                </div>
                                              </>
                                            )}


                                            {person.passport_doc && (
                                              <>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <span>Key person&nbsp;{personIndex + 1}&nbsp;{person.passport_doc.length > 8 ? (
                                                    person.passport_doc.slice(0, 8)
                                                  ) : (
                                                    person.passport_doc
                                                  )}</span>
                                                </div>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <Button color="link" onClick={() => handleDownloadDocument(person.passport_doc, item.accountId)}>
                                                    Download
                                                  </Button>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        ))
                                      )}

                                    </div>
                                  )}
                                </td>
                                <td className="table-td back-btn"><button className="view-aplcn" onClick={() => { handleViewApplication(item?.accountId) }}> View Application</button></td>
                              </tr>
                            )
                          }
                          )}
                        </tbody>

                      </Table>
                      <div className="d-flex justify-content-between">
                        <Button
                          className="button-account-continue"                          
                          disabled={pendingCurrentPage < 1}
                          onClick={() => handleGetAccountStatus(pendingCurrentPage - 1)}
                        >
                          Previous
                        </Button>
                        <div>
                          Page {pendingCurrentPage} of {pendingTotalPages}
                        </div>
                        <Button
                          color="primary"
                          // disabled={pendingCurrentPage >= pendingTotalPages}
                          onClick={() => handleGetAccountStatus(pendingCurrentPage + 1)}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )
                  }
                </div>
              )}
              {activeTab === 'customeAccountDetailscomplete' && (
                <div className="user-profile-text1">
                  <div className='row pb-3'>
                    <div className="col-md-3 date-picker-container  align-content-end">
                        <label htmlFor="start-date">Start Date:</label>
                        <Input type="date" name="startDatecomplete" className='new-date-box' value={startDatecomplete} onChange={e => setStartDatecomplete(e.target.value)} />
                      </div>
                      <div className="col-md-3 date-picker-container  align-content-end">
                        <label htmlFor="end-date">End Date:</label>
                        <Input type="date" name="endDatecomplete" className='new-date-box' value={endDatecomplete} onChange={e => setEndDatecomplete(e.target.value)} />
                      </div>
                      <div className="col-md-6 align-content-end">
                      <Button color="primary" className="pt-2" onClick={() => handleGetAccountCompletedStatus(completedCurrentPage)}>
                        Submit
                      </Button>&nbsp;
                      <Button 
                        color="primary" 
                        onClick={handleGetPendingTask} 
                        disabled={loaading} 
                      >
                        {loaading ? 'Updating...' : 'Status Update'}  
                      </Button>
                      </div>
                  </div>
                  {CompletedAccounts && (
                    <>
                      <Table responsive className="table-striped table-bordered custom-table-td">
                        <thead>
                          <tr className='new-form-table-row-two'>
                            <th>Customer Name</th>
                            <th>Email</th>
                            <th>Product</th>
                            <th>Account Type</th>
                            <th>Secondary Applicant Name & Email Id</th>
                            <th>Created Date</th>
                            <th>IBKR Account Status</th>
                            <th>Task Pending for Account Activation</th>
                            <th>Investment Amount</th>
                            <th>View Documents</th>
                            <th>View Application</th>
                          </tr>
                        </thead>
                        <tbody className='new-form-table-row-two'>
                          {CompletedAccounts?.map((item: any) => {

                            return (
                              <tr className='new-form-table-row-two' key={item?.id}>
                                <td className="table-td">

                                  {item?.accountHolderType === "Organization" ? (
                                    item?.company_details?.company_name
                                  ) : (
                                    <>
                                      {item?.accountHolderName}&nbsp;&nbsp;{item?.accountHolderSurname}
                                    </>
                                  )}
                                </td>
                                <td className="table-td">
                                  {item.accountHolderType === "Organization" ? (

                                    <>
                                      {item.accountHolderType} has no email address
                                    </>
                                  ) : (
                                    <>
                                      {item?.accountHolderEmail}</>
                                  )}</td>
                                <td className="table-td">{item?.accountMasterType}</td>
                                <td className="table-td">{item?.accountHolderType}</td>
                                <td className="table-td">
                                  {item?.jointAccount && item?.joint_details ? (
                                    <>
                                      {item?.joint_details?.jointfirstname} <br />
                                      {item?.joint_details?.joint_username}
                                    </>
                                  ) : (
                                    <>
                                      {/* {item?.accountHolderType} */}
                                      Not Applicable</>
                                  )}
                                </td>
                                <td className="table-td">{item?.updated_at}</td>
                                <td className="table-td">{item?.ibkr_account_status}</td>
                                <td>
                                  {item?.ibkr_pending_task && item?.ibkr_pending_task?.length > 0 ? (
                                    <ul>
                                       {/* {item.ibkr_pending_task.filter((obj) => obj.isRequiredForApproval === 'true').map((obj, index) => */}
                                      {item.ibkr_pending_task?.map((obj, index) =>

                                        (
                                        <li key={index}>
                                          {obj.formNumber} - {obj.formName}
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    <span>No pending tasks remaining</span>
                                  )}
                                </td>
                                <td className="table-td">{item?.investmentAmount}</td>
                                <td className="table-td">
                                  {item?.passportDoc && (
                                    <div className='col-12 text-center'>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        {item?.passportDoc && (
                                          <>
                                            <span> Primary Applicant Passport</span></>
                                        )}
                                      </div>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        <Button
                                          color="link"
                                          onClick={() => { handleDownloadDocument(item?.passportDoc, item?.accountId) }}
                                        >
                                          Download
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                  {item?.ppsNumberDoc && (
                                    <div className='col-12 text-center'>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        {item?.ppsNumberDoc && (
                                          <>
                                            <span>Primary Applicant PPS</span></>
                                        )}
                                      </div>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        <Button
                                          color="link"
                                          onClick={() => { handleDownloadDocument(item.ppsNumberDoc, item.accountId) }}
                                        >
                                          Download
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                  {item?.jointAccount && item?.joint_details && (
                                    <>
                                      {item?.joint_details?.joint_passport_doc && (
                                        <div className='col-12 text-center'>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            {item?.joint_details?.joint_passport_doc && (
                                              <span>Secondary Applicant Passport </span>
                                            )}
                                          </div>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            <Button
                                              color="link"
                                              onClick={() => { handleDownloadDocument(item?.joint_details?.joint_passport_doc, item?.accountId) }}
                                            >
                                              Download
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                      {item?.joint_details?.joint_pps_doc && (
                                        <div className='col-12 text-center'>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            {item?.joint_details?.joint_pps_doc && (
                                              <span>Secondary Applicant PPS</span>
                                            )}
                                          </div>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            <Button
                                              color="link"
                                              onClick={() => { handleDownloadDocument(item?.joint_details.joint_pps_doc, item.accountId) }}
                                            >
                                              Download
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {item?.company_details && (
                                    <div className="col-12 text-center" >
                                      {item?.company_details && item?.company_details.proof_of_company_doc && (
                                        <>
                                          <div style={{ backgroundColor: 'transparent', border: 'none', fontSize: "12px" }}>
                                            <span>{item.company_details.proof_of_company_doc?.length > 13 ? (
                                              item?.company_details?.proof_of_company_doc?.slice(0, 13)
                                            ) : (
                                              item?.company_details?.proof_of_company_doc

                                            )}</span>
                                          </div>
                                          <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                            <Button color="link" onClick={() => handleDownloadDocument(item?.company_details.proof_of_company_doc, item?.accountId)}>
                                              Download
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {item.company_details.authorized_person_details && (
                                        item.company_details.authorized_person_details.map((person, personIndex) => (
                                          <div key={personIndex} className="">

                                            {person.proof_of_address && (
                                              <>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <span>Key person&nbsp;{personIndex + 1}&nbsp;{person.proof_of_address.length > 16 ? (
                                                    person.proof_of_address.slice(0, 16)
                                                  ) : (
                                                    person.proof_of_address
                                                  )}</span>
                                                </div>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <Button color="link" onClick={() => handleDownloadDocument(person.proof_of_address, item.accountId)}>
                                                    Download
                                                  </Button>
                                                </div>
                                              </>
                                            )}


                                            {person.passport_doc && (
                                              <>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <span>Key person&nbsp;{personIndex + 1}&nbsp;{person.passport_doc.length > 8 ? (
                                                    person.passport_doc.slice(0, 8)
                                                  ) : (
                                                    person.passport_doc
                                                  )}</span>
                                                </div>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <Button color="link" onClick={() => handleDownloadDocument(person.passport_doc, item.accountId)}>
                                                    Download
                                                  </Button>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        ))
                                      )}

                                    </div>
                                  )}
                                </td>
                                <td className="table-td back-btn"><button className="view-aplcn" onClick={() => { handleViewApplication(item?.accountId) }}> View Application</button></td>
                              </tr>
                            )
                          }
                          )}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-between">
                        <Button
                          className="button-account-continue"                          
                          disabled={completedCurrentPage < 1}
                          onClick={() => handleGetAccountCompletedStatus(completedCurrentPage - 1)}
                        >
                          Previous
                        </Button>
                        <div>
                          Page {completedCurrentPage} of {completedTotalPages}
                        </div>
                        <Button
                          color="primary"
                          // disabled={completedCurrentPage >= completedTotalPages}
                          onClick={() => handleGetAccountCompletedStatus(completedCurrentPage + 1)}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}
               {activeTab === 'ibkrRejectedDetails' && (
                <div className="user-profile-text1">
                 <div className="row pb-3">
  <div className="col-md-3 date-picker-container align-content-end">
    <label htmlFor="start-date">Start Date:</label>
    <Input type="date" name="startDatecomplete" className='new-date-box' value={startDatereject} onChange={e => setStartDatereject(e.target.value)} />
  </div>
  <div className="col-md-3 date-picker-container align-content-end">
    <label htmlFor="end-date">End Date:</label>
    <Input type="date" name="endDatecomplete" className='new-date-box' value={endDatereject} onChange={e => setEndDaterejecte(e.target.value)} />
  </div>
  <div className="col-md-6">
    <div className="d-flex align-items-end" style={{ height: '100%' }}>
      <Button 
        color="primary" 
        className="me-2" 
        onClick={() => handleGetAccountRejecteddStatus(rejectedCurrentPage)}
      >
        Submit
      </Button>
      
      <Input
        type="text"
        placeholder="Search by name..."
        value={searchName}
        onChange={(e) => setSearchName(e.target.value)}
        className="search-input me-2"
        style={{ 
          width: '200px',
          border: '1px solid #ced4da',
          borderRadius: '4px'
        }}
      />
      <Button
        color="primary"
        onClick={handleSearchRejected}
        className="search-button"
      >
        <FontAwesomeIcon icon={faSearch} />
      </Button>
    </div>
  </div>
</div>
                       {CompletedAccounts && (
                      <>
                      <Table responsive className="table-striped table-bordered custom-table-td"  >
                        <thead>
                          <tr className='new-form-table-row-two' style={{backgroundColor:"#fff"}}>
                            <th>Customer Name</th>
                            <th>Email</th>
                            <th>Product</th>
                            <th>Account Type</th>
                            <th>Created Date</th>
                            <th>Investment Amount</th>
                            <th>View Documents</th>
                            <th>View Application</th>
                            <th>IBKR Account Status</th>
                            <th>Reason</th>
                          
                            <th>Send Mail For</th>
                          </tr>
                        </thead>
                        <tbody className='new-form-table-row-two'>
                          {rejectedAccounts?.map((item: any) => {

                            return (
                              <tr key={item.id}>
                                <td className="table-td">
                                  {item?.accountHolderType === "Organization" ? (
                                    item?.company_details?.company_name
                                  ) : (
                                    <>
                                      {item?.accountHolderName}&nbsp;&nbsp;{item?.accountHolderSurname}
                                    </>
                                  )}
                                </td>
                                <td className="table-td">
                                  {item.accountHolderType === "Organization" ? (

                                    <>
                                      {item?.accountHolderType} has no email address
                                    </>
                                  ) : (
                                    <>
                                      {item?.accountHolderEmail}</>
                                  )}</td>
                                <td className="table-td">{item?.accountMasterType}</td>
                                <td className="table-td">{item?.accountHolderType}</td>
                                <td className="table-td">{item?.updated_at}</td>
                                <td className="table-td">{item.investmentAmount}</td>
                                <td className="table-td">
                                  {item?.passportDoc && (
                                    <div className='col-12 text-center'>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        {item?.passportDoc && (
                                          <>
                                            <span> Primary Applicant Passport</span></>
                                        )}
                                      </div>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        <Button
                                          color="link"
                                          onClick={() => { handleDownloadDocument(item?.passportDoc, item?.accountId) }}
                                        >
                                          Download
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                  {item?.ppsNumberDoc && (
                                    <div className='col-12 text-center'>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        {item?.ppsNumberDoc && (
                                          <>
                                            <span>Primary Applicant PPS</span></>
                                        )}
                                      </div>
                                      <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                        <Button
                                          color="link"
                                          onClick={() => { handleDownloadDocument(item.ppsNumberDoc, item.accountId) }}
                                        >
                                          Download
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                  {item?.jointAccount && item?.joint_details && (
                                    <>
                                      {item?.joint_details?.joint_passport_doc && (
                                        <div className='col-12 text-center'>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            {item?.joint_details?.joint_passport_doc && (
                                              <span>Secondary Applicant Passport </span>
                                            )}
                                          </div>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            <Button
                                              color="link"
                                              onClick={() => { handleDownloadDocument(item?.joint_details?.joint_passport_doc, item?.accountId) }}
                                            >
                                              Download
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                      {item?.joint_details?.joint_pps_doc && (
                                        <div className='col-12 text-center'>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            {item?.joint_details?.joint_pps_doc && (
                                              <span>Secondary Applicant PPS</span>
                                            )}
                                          </div>
                                          <div className='' style={{ backgroundColor: 'transparent', border: "none" }}>
                                            <Button
                                              color="link"
                                              onClick={() => { handleDownloadDocument(item?.joint_details.joint_pps_doc, item.accountId) }}
                                            >
                                              Download
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {item?.company_details && (
                                    <div className="col-12 text-center" >
                                      {item?.company_details && item?.company_details.proof_of_company_doc && (
                                        <>
                                          <div style={{ backgroundColor: 'transparent', border: 'none', fontSize: "12px" }}>
                                            <span>{item.company_details.proof_of_company_doc?.length > 13 ? (
                                              item?.company_details?.proof_of_company_doc?.slice(0, 13)
                                            ) : (
                                              item?.company_details?.proof_of_company_doc

                                            )}</span>
                                          </div>
                                          <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                            <Button color="link" onClick={() => handleDownloadDocument(item?.company_details.proof_of_company_doc, item?.accountId)}>
                                              Download
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {item.company_details.authorized_person_details && (
                                        item.company_details.authorized_person_details.map((person, personIndex) => (
                                          <div key={personIndex} className="">

                                            {person.proof_of_address && (
                                              <>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <span>Key person&nbsp;{personIndex + 1}&nbsp;{person.proof_of_address.length > 16 ? (
                                                    person.proof_of_address.slice(0, 16)
                                                  ) : (
                                                    person.proof_of_address
                                                  )}</span>
                                                </div>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <Button color="link" onClick={() => handleDownloadDocument(person.proof_of_address, item.accountId)}>
                                                    Download
                                                  </Button>
                                                </div>
                                              </>
                                            )}


                                            {person.passport_doc && (
                                              <>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <span>Key person&nbsp;{personIndex + 1}&nbsp;{person.passport_doc.length > 8 ? (
                                                    person.passport_doc.slice(0, 8)
                                                  ) : (
                                                    person.passport_doc
                                                  )}</span>
                                                </div>
                                                <div className="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                  <Button color="link" onClick={() => handleDownloadDocument(person.passport_doc, item.accountId)}>
                                                    Download
                                                  </Button>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        ))
                                      )}

                                    </div>
                                  )}
                                </td>
                                <td className="table-td back-btn"><button className="view-aplcn" onClick={() => { handleViewApplication(item?.accountId) }}> View Application</button></td>
                                <td className="table-td">{item?.ibkr_account_status}</td>
                                <td className="table-td">{item?.ibkr_errors == "Please provide valid signature for Tax Form Number 5001" ? (
                                  "Please provide valid signature for Tax Form W8Ben"
                                ) : (<>{item.ibkr_errors}</>)}</td>
                                <td className="table-td">

                                  {/* <Button
                                  color="primary"
                                  className='m-2'
                                  onClick={() => handleSendRejectedMail(item?.accountId)}
                                >Application Info Update</Button>  */}

                                  <Button className='m-2'
                                    color="primary" onClick={() => toggleOutSource(item?.accountId)}>Need More Info</Button></td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-between">
                        <Button
                          className="button-account-continue"                          
                          disabled={rejectedCurrentPage < 1}
                          onClick={() => handleGetAccountRejecteddStatus(rejectedCurrentPage - 1)}
                        >
                          Previous
                        </Button>
                        <div>
                          Page {rejectedCurrentPage} of {rejectedTotalPages}
                        </div>
                        <Button
                          color="primary"
                          // disabled={rejectedCurrentPage >= rejectedTotalPages}
                          onClick={() => handleGetAccountRejecteddStatus(rejectedCurrentPage + 1)}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )
                  }
                </div>
              )}
              {activeTab === 'needInfo' && (
           <div className="user-profile-text1">
          <div className='row pb-3'>
            <div className="col-md-4 date-picker-container d-flex align-items-end">
              <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                  <label htmlFor="email">Enter Email Address</label>
                  <input 
                    type="email" 
                    className="form-control" 
                    id="email" 
                    placeholder="Enter Email Address" 
                    value={emailnew}
                    onChange={(e) => setEmailNew(e.target.value)}
                    required 
                    style={{ width: '100%' }} // Set the width to 100%
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="subject">Enter Subject</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="subject" 
                    placeholder="Enter Subject" 
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required 
                    style={{ width: '100%' }} // Set the width to 100%
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="message">Enter Message</label>
                  <textarea 
                    className="form-control" 
                    id="message" 
                    rows={3} 
                    placeholder="Salutation Content" 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    style={{ width: '300%' }} // Set the width to 100%
                  ></textarea>
                </div>

                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
              )}
              {activeTab === 'WithdrawalList' && (
                <div className="user-profile-text1">
                 <div className="row pb-3">
  <div className="col-md-3 date-picker-container align-content-end">
    <label htmlFor="start-date">Start Date:</label>
    <Input 
      type="date" 
      className='new-date-box' 
      name="startDate" 
      value={withdrawalstartDatecomplete} 
      onChange={e => setWithdrawalStartDatecomplete(e.target.value)} 
    />
  </div>
  <div className="col-md-3 date-picker-container align-content-end">
    <label htmlFor="end-date">End Date:</label>
    <Input 
      type="date" 
      name="endDate" 
      className='new-date-box' 
      value={withdrawalEndDatecomplete} 
      onChange={e => setWithdrawalEndDatecomplete(e.target.value)} 
    />
  </div>
  <div className="col-md-6">
    <div className="d-flex align-items-end" style={{ height: '100%' }}>
      <Button 
        color="primary" 
        className="me-2"
        onClick={() => { handleGetWithdrawalList() }}
      >
        Submit
      </Button>

      <Input
        type="text"
        placeholder="Search by name..."
        value={searchName}
        onChange={(e) => setSearchName(e.target.value)}
        className="search-input me-2"
        style={{ 
          width: '200px',
          border: '1px solid #ced4da',
          borderRadius: '4px'
        }}
      />
      <Button
        color="primary"
        onClick={handleSearchWithdraw}
        className="search-button me-2"
      >
        <FontAwesomeIcon icon={faSearch} />
      </Button>

      {withdrawalAccounts && (
        <Button color="primary" onClick={handleWithdrawalReport}>
          Download Report
        </Button>
      )}
    </div>
  </div>
</div>
                  {withdrawalAccounts && (
                    <>
                      <Table responsive className="table-striped table-bordered custom-table-td" >
                        <thead>
                          <tr className='new-form-table-row-two' style={{backgroundColor:"#ffffff"}}>
                            <th>IBKR AccountNumber</th>
                            <th>Client Name</th>
                            <th>Transaction Type</th>
                            <th>Amount of Withdraw</th>
                            <th>Currency of Withdraw</th>
                            <th>Created At GMT</th>
                            <th>Approval Status</th>                          
                          </tr>
                        </thead>

                        <tbody className='new-form-table-row-two'>
                          {withdrawalAccounts.map((item) => {

                            return (
                              <tr className='new-form-table-row-two'
                                key={item.id}
                                onMouseEnter={() => setHoveredItemId(item.id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                              >
                                <td className="table-td">
                                      {item.ibkrAccountNumber}
                                </td>
                                <td className="table-td">  {item.userName} </td>
                                <td className="table-td">
                                {item.debitCreditFlag === 'DR' ? 'Withdraw' : item.debitCreditFlag}
                              </td>
                                <td className="table-td">
                                      {item.debit}
                                </td>
                                <td className="table-td"> {item.currency}</td>
                                <td className="table-td">
                                      {item.createdAtGMT}
                                </td>
                                <td className="table-td hover-edit">
                                  {editMode[item.id] ? (
                                    <div className="status-dropdown">
                                      <Button
                                      color="primary"
                                      onClick={() => handleIbkrApproval(item.id, 'COMPLETED')}
                                      value="COMPLETED"
                                    >
                                      Completed
                                    </Button>

                                    </div>
                                  ) : (
                                    <span>                                
                                      {hoveredItemId === item.id && (
                                        <span className="pencil-icon" onClick={() => { toggleSelectBox(item.id) }}>
                                          <PencilFill className="edit-button-style" />
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </td>
                                                                                    
                              </tr>
                            )
                          }
                          )}
                        </tbody>

                      </Table>
                      <div className="d-flex justify-content-between">
                        <Button
                          className="button-account-continue"                          
                          disabled={pendingCurrentPage < 1}
                          onClick={() => handleGetAccountStatus(pendingCurrentPage - 1)}
                        >
                          Previous
                        </Button>
                        <div>
                          Page {pendingCurrentPage} of {pendingTotalPages}
                        </div>
                        <Button
                          color="primary"
                          // disabled={pendingCurrentPage >= pendingTotalPages}
                          onClick={() => handleGetAccountStatus(pendingCurrentPage + 1)}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  
                )
                  }
                </div>
              )}
                {activeTab === 'DepositList' && (
                <div className="user-profile-text1">
<div className="row pb-3">
  <div className="col-md-3 date-picker-container align-content-end">
    <label htmlFor="start-date">Start Date:</label>
    <Input 
      type="date" 
      className='new-date-box' 
      name="startDate" 
      value={depositstartDatecomplete} 
      onChange={e => setDepositStartDatecomplete(e.target.value)} 
    />
  </div>
  <div className="col-md-3 date-picker-container align-content-end">
    <label htmlFor="end-date">End Date:</label>
    <Input 
      type="date" 
      name="endDate" 
      className='new-date-box' 
      value={depositEndDatecomplete} 
      onChange={e => setDepositEndDatecomplete(e.target.value)} 
    />
  </div>
  <div className="col-md-6">
    <div className="d-flex align-items-end" style={{ height: '100%' }}>
      <Button 
        color="primary" 
        className="me-3"
        onClick={() => { handleGetDepositList() }}
      >
        Submit
      </Button>

      <div className="d-flex align-items-center me-3">
        <Input
          type="text"
          placeholder="Search by name..."
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          className="search-input me-2"
          style={{ 
            width: '200px',
            border: '1px solid #ced4da',
            borderRadius: '4px'
          }}
        />
        <Button
          color="primary"
          onClick={handleSearchDeposit}
          className="search-button"
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </div>

      {depositAccounts && (
        <Button color="primary" onClick={handleDepositReport}>
          Download Report
        </Button>
      )}
    </div>
  </div>
</div>
                  {depositAccounts && (
                    <>
                      <Table responsive className="table-striped table-bordered custom-table-td" >
                        <thead>
                          <tr className='new-form-table-row-two' style={{backgroundColor:"#ffffff"}}>
                            <th>IBKR AccountNumber</th>
                            <th>Client Name</th>
                            <th>Transaction Type</th>
                            <th>Amount of Deposit</th>
                            <th>Currency of Deposit</th>
                            <th>Created At GMT</th>
                            <th>Approval Status</th>                          
                          </tr>
                        </thead>

                        <tbody className='new-form-table-row-two'>
                          {depositAccounts.map((item) => {

                            return (
                              <tr className='new-form-table-row-two'
                                key={item.id}
                                onMouseEnter={() => setHoveredItemId(item.id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                              >
                                <td className="table-td">
                                      {item.ibkrAccountNumber}
                                </td>
                                <td className="table-td">
                                     {item.userName}
                                </td>
                                <td className="table-td">
                                {item.debitCreditFlag === 'CR' ? 'Deposit' : item.debitCreditFlag}
                              </td>
                                <td className="table-td">
                                      {item.credit}
                                </td>
                                <td className="table-td">
                                      {item.currency}
                                </td>
                                <td className="table-td">
                                      {item.createdAtGMT}
                                </td>
                                <td className="table-td hover-edit">
                                  {editMode[item.id] ? (
                                    <div className="status-dropdown">
                                      <Button
                                      color="primary"
                                      onClick={() => handleIbkrApproval(item.id, 'COMPLETED')}
                                      value="COMPLETED"
                                    >
                                      Completed
                                    </Button>

                                    </div>
                                  ) : (
                                    <span>                                
                                      {hoveredItemId === item.id && (
                                        <span className="pencil-icon" onClick={() => { toggleSelectBox(item.id) }}>
                                          <PencilFill className="edit-button-style" />
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </td>
                                                                                    
                              </tr>
                            )
                          }
                          )}
                        </tbody>

                      </Table>
                      <div className="d-flex justify-content-between">
                        <Button
                          className="button-account-continue"                          
                          disabled={pendingCurrentPage < 1}
                          onClick={() => handleGetAccountStatus(pendingCurrentPage - 1)}
                        >
                          Previous
                        </Button>
                        <div>
                          Page {pendingCurrentPage} of {pendingTotalPages}
                        </div>
                        <Button
                          color="primary"
                          // disabled={pendingCurrentPage >= pendingTotalPages}
                          onClick={() => handleGetAccountStatus(pendingCurrentPage + 1)}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  
                )
                  }
                </div>
              )}
             
            </div>
          </div>
        </main>
        {/* STATus modal */}

        <Row className='justify-content-center'>
          <Col md={12}>
            <CommonToast

              visible={open}
              // onClose={toggle7}

              needCloseButton={true}
              type="custom"
              isAutoClose={false}
            >
              {loading ? (
                <div className='justify-content-center row font-weight-bold custom-modal-style'>
                  <p>Please wait, approval in progress...</p>
                  <Lottie animationData={animationData} loop autoplay style={{ width: '50px', height: '50px', alignSelf: 'center', justifyContent: 'center' }} />
                </div>
              ) : (
                <div className="font-weight-bold custom-modal-style">
                  <p>Are you sure you want to update the status to <strong>{selectedOptions?.status}</strong>?</p>
                  {selectedOptions?.status === 'NEED_MORE_INFO' && (
                    <textarea
                      className="form-control mt-3"
                      placeholder="Please provide additional information here..."
                      value={selectedOptions?.description}
                      onChange={(e) => setSelectedOptions({
                        ...selectedOptions,
                        description: e.target.value,
                      })}
                    />
                  )}
                </div>
              )}
              <div className='flex justify-content-end g-10'>
                {!loading && (
                  <>
                    <Button color="primary" onClick={handleModalConfirm}>
                      OK
                    </Button>
                    <Button color="primary" className='ms-2' onClick={handleModalClose}>
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </CommonToast>
          </Col>
        </Row>
        <div className='d-flex align-items-center'>
        <CommonToast
        visible={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        // title={'Test'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        <div className="align-items-center custom-content">
 
          <div className="font-weight-bold custom-modal-style mb-3" >
            Email sent successfully.
          </div>
          <Button color="primary mt-1" onClick={() => {
            setShowSuccessModal(false);
          }}>
            Ok
          </Button>
        </div>
      </CommonToast>
      </div>
      <div className='d-flex align-items-center'>
        <CommonToast
        visible={completedstatus}
        onClose={() => setCompletedStatus(false)}
        // title={'Test'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        <div className="align-items-center custom-content">
 
          <div className="font-weight-bold custom-modal-style mb-3" >
            Status Updated Successfully
          </div>
          <Button color="primary mt-1" onClick={() => {
            setCompletedStatus(false);
          }}>
            Ok
          </Button>
        </div>
      </CommonToast>
      </div>
        {/* //outsource */}
        <Row className='justify-content-center'>
          <Col md={12}>
            <CommonToast
              visible={openOutSource}
              // onClose={toggle7}
              title={'Enter the required information below'}
              needCloseButton={true}
              type="custom"
              isAutoClose={false}
            >
              <div className="font-weight-bold custom-modal-style">


                <textarea
                  className="form-control mt-3"
                  placeholder="Additional information"
                  value={outSorcingData}
                  onChange={(e) => setrOutSourcingData(
                    e.target.value,
                  )}
                />

              </div>
              <Button color="primary" disabled={!outSorcingData} onClick={handleSendRejectedReasonMail} className='ms-2 ok-button-disabled'>
                OK
              </Button>
              <Button color="secondary" className='ms-2' onClick={handletoggleOutSourceClose}>
                Cancel
              </Button>

            </CommonToast>
          </Col>
        </Row>

      </Row>
    </div >
  );
};

export default AdminProfile;

