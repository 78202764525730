import { useAppDispatch, useAppSelector } from 'app/config/store';
import { depositConfig, getEntity, setAccountLastLevel } from 'app/entities/accounts/accounts.reducer'
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import React, { useEffect, useState } from 'react'
import { Accordion, Button, Card, CardBody, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router';

const IndividualTask = () => {

          const dispatch = useAppDispatch();
          const navigate = useNavigate();
          const { last_level } = useAppSelector(state => state.accounts.entity);
          const mainAccountsDetails = useAppSelector(state => state.accounts?.entity);
          const accountsEntity = useAppSelector(state => state.accounts.entity);
            console.log("accountsEntity", accountsEntity);
            
  
          const isTaskPending = (taskId: number) => {
            const pendingTasks = [2, 3, 4, 5, 6, 7, 8, 9, 10, 99, 100, 101, 102, 103, 104];
            return pendingTasks.includes(taskId);
          };
 
        const onClickPendingTask = (level: number = 1) => {
            dispatch(setAccountLastLevel(level));
            navigate('/accounts');
            };
return(        

    <>
        <div className="pt-5 accordion-div-dashboard">
          <Card className="dashboard-custom-card pt-3">
            <CardBody>
              <div className="dashboard-card-heading">My Pending Items & Tasks</div>
              <Accordion alwaysOpen defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']}>
                {last_level < 3 && isTaskPending(2) && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Primary Applicant Details
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          <Button onClick={() => onClickPendingTask(2)} className="button-dashboard-custom-submit">
                            Primary Applicant Details
                          </Button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {last_level < 4 && isTaskPending(3) && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Primary Applicant Employment Details
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 3 ? (
                            <Button onClick={() => onClickPendingTask(3)} className="button-dashboard-custom-submit">
                              Primary Applicant Employment Details
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Primary Applicant Employment Details
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {mainAccountsDetails?.accountHolderType !== 'Individual' && last_level < 5 && isTaskPending(4) && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Secondary Applicant Details
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 4 ? (
                            <Button onClick={() => onClickPendingTask(4)} className="button-dashboard-custom-submit">
                              Secondary Applicant Details
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Secondary Applicant Details
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {last_level < 6 && isTaskPending(5) && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Anti-Money Laundering Requirements
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {(last_level === 5 || (last_level === 4 && mainAccountsDetails?.accountHolderType === 'Individual')) ? (
                            <Button onClick={() => onClickPendingTask(5)} className="button-dashboard-custom-submit">
                              Anti-Money Laundering Requirements
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Anti-Money Laundering Requirements
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {last_level < 7 && isTaskPending(6) && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Your Net Worth and Annual Income
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 6 ? (
                            <Button onClick={() => onClickPendingTask(6)} className="button-dashboard-custom-submit">
                              Your Net Worth and Annual Income
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Your Net Worth and Annual Income
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {last_level < 8 && isTaskPending(7) && (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Politically Exposed Person (PEP)
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 7 ? (
                            <Button onClick={() => onClickPendingTask(7)} className="button-dashboard-custom-submit">
                              Politically Exposed Person (PEP)
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Politically Exposed Person (PEP)
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                )}
                {last_level < 9 && isTaskPending(8) && (
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Regulated Employee Clients
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 8 ? (
                            <Button onClick={() => onClickPendingTask(8)} className="button-dashboard-custom-submit">
                              Regulated Employee Clients
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Regulated Employee Clients
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                )}
                {last_level < 10 && isTaskPending(9) && (
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Know Your Customer Verification
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 9 ? (
                            <Button onClick={() => onClickPendingTask(9)} className="button-dashboard-custom-submit">
                              Know Your Customer Verification
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Know Your Customer Verification
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                )}
                {last_level < 11 && isTaskPending(10) && (
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;My Starlight Smart Reserve Application Summary
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 10 ? (
                            <Button onClick={() => onClickPendingTask(10)} className="button-dashboard-custom-submit">
                              My Starlight Smart Reserve Application Summary
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              My Starlight Smart Reserve Application Summary
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                )}
                {last_level < 101 && isTaskPending(100) && (
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Statement of Suitability
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 100 ? (
                            <Button onClick={() => onClickPendingTask(100)} className="button-dashboard-custom-submit">
                              Statement of Suitability
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Statement of Suitability
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                )}
                {last_level < 102 && isTaskPending(101) && (
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Starlight Smart Reserve Model Portfolio Description
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 101 ? (
                            <Button onClick={() => onClickPendingTask(101)} className="button-dashboard-custom-submit">
                              Starlight Smart Reserve Model Portfolio Description
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Starlight Smart Reserve Model Portfolio Description
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                )}
                {last_level < 103 && isTaskPending(102) && (
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;W-8BEN Primary Form
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 102 ? (
                            <Button onClick={() => onClickPendingTask(102)} className="button-dashboard-custom-submit">
                              W-8BEN
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              W-8BEN
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                )}
                {last_level < 104 && isTaskPending(103) && (
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      <div className="dashboard-card-sub-heading">
                        <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Interactive Brokers Ireland Standard Terms And Disclosures
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          {last_level === 103 ? (
                            <Button onClick={() => onClickPendingTask(104)} className="button-dashboard-custom-submit">
                              Interactive Brokers Ireland Standard Terms And Disclosures
                            </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Interactive Brokers Ireland Standard Terms And Disclosures
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                )}
              </Accordion>
            </CardBody>
          </Card>
        </div>
      </>
)
}
export default IndividualTask