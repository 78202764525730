import React, { useCallback, useEffect, useState } from 'react'
import { HouseDoor } from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "./style.scss"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Popover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCopy, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getIbkr, getIbkrreset, getPortfolio, updateEmail } from 'app/entities/accounts/accounts.reducer';
import { getCustomerDetails, ibkrportal } from "app/modules/profile/profile.reducer";
import debounce from "lodash.debounce"; // Debounce function for performance
// import { updateEmail } from 'app/entities/admin-profile/admin.reducer'; // You'll need to create this action




const navbarlinks = [
  {
    label: "My Starlight",
    value: "Home",
    navlink: "/dashboard",
  },
  {
    label: "Deposit",
    value: "Deposit",
    navlink: "/dashboard/deposit",
  },
  {
    label: "Withdraw",
    value: "Withdraw",
    navlink: "/dashboard/withdraw",
  },
  {
    label: "Investment Portal",
    value: "InvestmentPortal",
    navlink: "/dashboard",
  },

  {
    label: "Documents",
    value: "Documents",

  },
  {
    label: "Security",
    value: "Security",

  },
  {
    label: "Support",
    value: "Support",

  },


];

const DashboardHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const Toggle = () => setIsOpen(!isOpen);
  const [selecetedNav, setSelecetdNav] = useState("Home");
  const [ibkrData, setIbkrData] = useState(null);
  const [ibkrNum, setIbkrNum] = useState(null);
  const [portfolioData, setPortfolioData] = useState(null);
  const customerDetails = useAppSelector(state => state.customer.entities);
  const dispatch = useAppDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 540);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewEmail(email);
    // Clear error when user starts typing
    setEmailError('');
  };

  const handleResize = useCallback(
    debounce(() => {
      setIsMobile(window.innerWidth < 540);
    }, 200),
    []
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const handleIBKRPortalLinkClick = async () => {
    try {
      const resultAction = await dispatch(ibkrportal());

      if (resultAction && resultAction.payload) {
        const portalUrl = resultAction.payload;

        window.open(portalUrl, '_blank');
      } else {
        console.error('IBKR Portal URL not found');
      }
    } catch (error) {
      console.error('Error fetching IBKR Portal URL:', error);
    }
  };

  const handleSetActiveNavigation = (nav: string) => {
    setMobileSidebar(!mobileSidebar);
    if (nav === 'InvestmentPortal') {
      setSelecetdNav('Home')
    }
    else {
      setSelecetdNav(nav);
    }
    if (nav === 'InvestmentPortal' && ibkrNum) {
      handleIBKRPortalLinkClick();
    };
  };
  useEffect(() => {
    dispatch(getIbkr()).then((response: any) => {
      if (response?.payload) {
        setIbkrData(response.payload);
        setIbkrNum(response.payload.ibkr_account_number)

      }
    });
  }, [dispatch]);

  const handleIBKRResetLinkClick = async () => {
    try {
      const resetResult = await dispatch(getIbkrreset());

      if (resetResult?.payload) {
        await dispatch(getIbkr());
        window.location.reload();

      } else {
        console.error('Error: `getIbkrreset` did not return expected payload');
      }
    } catch (error) {
      console.error('Error in handleIBKRResetLinkClick:', error);
    }
  };



  useEffect(() => {
    if (ibkrData) {
      dispatch(getPortfolio(ibkrNum)).then((response: any) => {
        if (response?.payload) {
          setPortfolioData(response.payload);
        }

      });

    }
  }, [ibkrData]);

  const copyToClipboard = (text) => {
    if (!text) return; // Don't attempt to copy if there's no text
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  const navigate = useNavigate();

  const handleClick = () => {
    window.location.reload();

    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      navigate('/logout');
      if (window.location.pathname === '/logout') {
        window.location.reload();
      }
    }
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleSettingsDropdown = () => setSettingsDropdownOpen(!settingsDropdownOpen);
  const toggleEmailModal = () => {
    setEmailModalOpen(!emailModalOpen);
    setNewEmail('');
  };

  const handleEmailUpdate = async () => {
    if (!validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address');
      return;
    }
    const entity={
      userId: customerDetails?.customerId,
      email: newEmail,
    }
    try {
      await dispatch(updateEmail(entity));
      toggleEmailModal();
      // Show success message or handle the response
    } catch (error) {
      console.error('Failed to update email:', error);
      // Show error message
    }
  };

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/dashboard/deposit") {
      setSelecetdNav("Deposit");
    } else if (pathname === "/dashboard/withdraw") {
      setSelecetdNav("Withdraw");
    }
  });
  return (
    <header className="header-style header-style-custom ">
      <Navbar className='navhead navbar-container nav-head-one  pt-2 align-items-center'>
        <NavbarBrand tag={Link} to="https://www.starlightwealth.ie" className='color-nav-brand align-items-center'>
          <img
            src="../../../../../content/images/new_logo_ash_bg_color.svg"
            className='img-fluid logo-dashboard'
            alt=""
          />
        </NavbarBrand>
        {isMobile && (
          <>
            <Button onClick={() => setMobileSidebar(!mobileSidebar)}>
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </>
        )}
        {!isMobile && (
          <>
            <Nav className=" flex-column  flex-md-row align-items-center">
              <NavItem >
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle tag={NavLink} className="color-nav-items" caret>
                    <FontAwesomeIcon icon={faUser} /> {customerDetails?.firstName
                      ? customerDetails.firstName.charAt(0).toUpperCase() + customerDetails.firstName.slice(1)
                      : ''}
                    {customerDetails?.lastName
                      ? ' ' + customerDetails.lastName.charAt(0).toUpperCase() + customerDetails.lastName.slice(1)
                      : ''}
                  </DropdownToggle>
                  <DropdownMenu style={{ backgroundColor: "white", padding: "0px", border: "none" }}>
                    {/* <DropdownItem 
                      onClick={toggleEmailModal}
                      className="dropdown-item-custom"
                    >
                      Change Email
                    </DropdownItem> */}
                    <DropdownItem
                      tag={Link}
                      to="/logout"
                      onClick={handleClick}
                      className="dropdown-item-custom"
                      style={{ borderBottomWidth: "1px" }}
                    >
                      Log Out
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <NavLink className='color-nav-items'>
                  Account No:{ibkrData?.ibkr_account_number}
                  <button
                    className="btn btn-link p-0 ms-2"
                    onClick={() => copyToClipboard(ibkrData?.ibkr_account_number)}
                    title="Copy to clipboard"
                  >
                    <FontAwesomeIcon
                      icon={faCopy}
                      style={{ color: "#fff", cursor: 'pointer', transition: 'transform 0.2s' }}
                      onMouseEnter={(e: any) => e.target.style.transform = 'scale(1.2)'}
                      onMouseLeave={(e: any) => e.target.style.transform = 'scale(1)'}
                    />
                  </button>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className='color-nav-items'>Balance:€{portfolioData?.balance}</NavLink>
              </NavItem>
            </Nav>
          </>
        )}
      </Navbar>
      {!isMobile && (
        <>
          <Navbar
            expand="lg"
            className="navhead nav-head-two navbar-container pt-2"
          >



            <NavbarToggler onClick={Toggle} />

            <Collapse
              isOpen={isOpen}
              navbar
              className="collapse-custom text-start pt-2"
            >
              <Nav className=" flex-column  flex-lg-row">
                {navbarlinks.map((item) => (
                  <NavItem className='px-2' onClick={() => handleSetActiveNavigation(item.value)}>
                    <NavLink
                      tag={Link}
                      className={`nav-link nav-text relative-div-bg ${selecetedNav === item.value ? "navbaractive" : ""
                        }`}
                      to={item.navlink}
                    >
                      {item.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Collapse>
          </Navbar>
        </>
      )}

      {isMobile && mobileSidebar && (
        <>
          <div className="container-nav-mobile">
            <div className="container-navbar">
              <div className='nav-head-mobile'>
                <img
                  src="../../../../../content/images/new_logo_white_bg_color.svg"
                  className='img-fluid'
                  alt=""
                />
                <Button onClick={() => setMobileSidebar(!mobileSidebar)}>
                  <FontAwesomeIcon icon={faXmark} />
                </Button>
              </div>

              <Nav className=" flex-column  flex-lg-row">
                <div className='sub-heading-mobile-menu'>
                  Primary Details
                </div>
                <NavItem >
                  <NavLink className='nav-link nav-text relative-div-bg'>
                    <FontAwesomeIcon icon={faUser} />&nbsp;
                    {customerDetails?.firstName
                      ? customerDetails.firstName.charAt(0).toUpperCase() + customerDetails.firstName.slice(1)
                      : ''}
                    {customerDetails?.lastName
                      ? ' ' + customerDetails.lastName.charAt(0).toUpperCase() + customerDetails.lastName.slice(1)
                      : ''}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className='nav-link nav-text relative-div-bg'
                    onClick={() => copyToClipboard(ibkrData?.ibkr_account_number)}
                  >
                    Account No:{ibkrData?.ibkr_account_number}&nbsp;
                    <FontAwesomeIcon
                      icon={faCopy}
                      style={{ cursor: 'pointer', transition: 'transform 0.2s' }}
                      onMouseEnter={(e: any) => e.target.style.transform = 'scale(1.2)'}
                      onMouseLeave={(e: any) => e.target.style.transform = 'scale(1)'}
                    />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className='nav-link nav-text relative-div-bg'>Balance:€{portfolioData?.balance}</NavLink>
                </NavItem>
                <div className='sub-heading-mobile-menu'>
                  Menu
                </div>
                {navbarlinks.map((item) => (
                  <NavItem className='px-2' onClick={() => handleSetActiveNavigation(item.value)}>
                    <NavLink
                      tag={Link}
                      className={`nav-link nav-text relative-div-bg ${selecetedNav === item.value ? "navbaractive" : ""
                        }`}
                      to={item.navlink}
                    >
                      {item.label}
                    </NavLink>
                  </NavItem>
                ))}

                <div className='sub-heading-mobile-menu' />

                <NavItem className='px-2' onClick={handleClick}>
                  <NavLink
                    className="nav-link nav-text relative-div-bg"
                  >
                    Log Out
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </>
      )}

      {/* Email Change Modal */}
      <Modal isOpen={emailModalOpen} toggle={toggleEmailModal}>
        <ModalHeader toggle={toggleEmailModal}>Change Email Address</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="newEmail">New Email Address</Label>
              <Input
                type="email"
                id="newEmail"
                value={newEmail}
                onChange={handleEmailChange}
                placeholder="Enter new email address"
                invalid={!!emailError}
              />
                          {emailError && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {emailError}
              </div>
            )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleEmailModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleEmailUpdate}>
            Update Email
          </Button>
        </ModalFooter>
      </Modal>
    </header>

  )
}

export default DashboardHeader

