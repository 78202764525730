import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setAccountLastLevel } from 'app/entities/accounts/accounts.reducer';
import React, { useEffect, useState } from 'react'
import { Accordion, Button, Card, CardBody, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router';


const Organisation = ({PendingTasks}) => {
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
        
      const { last_level } = useAppSelector(state => state.accounts.entity);

      const onClickCompanyPendingTask = (level: number = 1) => {
        dispatch(setAccountLastLevel(level));
        navigate('/company');
      };

return(

    <>
       <div className="pt-5 accordion-div-dashboard">
         <Card className="dashboard-custom-card pt-3">
         <CardBody>
         <div className="dashboard-card-heading">My Pending Items & Tasks</div>
         <Accordion alwaysOpen defaultActiveKey={['0','1','2','3','4','5','6','7','8','9','10']}>
                  {last_level < 2 && (
                    <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    <div className="dashboard-card-sub-heading">
                            <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                          &nbsp;&nbsp;Company Details
                          </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                          <Button  onClick={() => onClickCompanyPendingTask(2)} className="button-dashboard-custom-submit">
                          Company Details
                          </Button>
                        </div>
                      </div>
                    </Accordion.Body>
                    </Accordion.Item>                    
                  )}
                  {last_level < 3 && (
                      <Accordion.Item eventKey="1">
                      <Accordion.Header>
                              <div className="dashboard-card-sub-heading">
                              <Button className="button-pending-dashboard">
                                Action Required
                              </Button>                                              
                            &nbsp;&nbsp;Key People associated with the Company
                              </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                          <div>
                            {last_level === 2 ? (
                           <Button onClick={() => onClickCompanyPendingTask(3)} className="button-dashboard-custom-submit">
                           Key People associated with the Company
                         </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Key People associated with the Company
                            </span>
                          )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>                            
                  )}
                  {last_level < 4 && (
                     <Accordion.Item eventKey="2">
                     <Accordion.Header>
                             <div className="dashboard-card-sub-heading"> 
                             <Button className="button-pending-dashboard">
                               Action Required
                             </Button>
                           &nbsp;&nbsp;Details of Company Key People
                             </div>
                     </Accordion.Header>
                     <Accordion.Body>
                       <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                         <div>
                            {last_level === 3 ? (
                             <Button onClick={() => onClickCompanyPendingTask(4)} className="button-dashboard-custom-submit">
                             Details of Company Key People 
                              </Button>
                         ) : (
                           <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Details of Company Key People 
                           </span>
                         )}
                         </div>
                       </div>
                     </Accordion.Body>
                   </Accordion.Item>                                  
                  )}
                  {last_level < 5 && (
                     <Accordion.Item eventKey="3">
                     <Accordion.Header>
                             <div className="dashboard-card-sub-heading"> 
                             <Button className="button-pending-dashboard">
                               Action Required
                             </Button>
                           &nbsp;&nbsp;Company Financials
                             </div>
                     </Accordion.Header>
                     <Accordion.Body>
                       <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                         <div>
                            {last_level === 4 ? (
                             <Button onClick={() => onClickCompanyPendingTask(5)} className="button-dashboard-custom-submit">
                             Company Financials
                              </Button>
                         ) : (
                           <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                             Company Financials
                           </span>
                         )}
                         </div>
                       </div>
                     </Accordion.Body>
                   </Accordion.Item>  
                   
                  )}
                  {last_level < 6 && (
                    <Accordion.Item eventKey="4">
                    <Accordion.Header>
                            <div className="dashboard-card-sub-heading"> 
                            <Button className="button-pending-dashboard">
                              Action Required
                            </Button>
                          &nbsp;&nbsp;My Starlight Smart Reserve Application Summary
                            </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                        <div>
                           {last_level === 5 ? (
                            <Button onClick={() => onClickCompanyPendingTask(6)} className="button-dashboard-custom-submit">
                            My Starlight Smart Reserve Application Summary
                             </Button>
                        ) : (
                          <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                            My Starlight Smart Reserve Application Summary
                          </span>
                        )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>  
                    
                  )}
                   {last_level < 100 && (
                        <Accordion.Item eventKey="5">
                        <Accordion.Header>
                                <div className="dashboard-card-sub-heading"> 
                                <Button className="button-pending-dashboard">
                                  Action Required
                                </Button>
                              &nbsp;&nbsp;Statement of Suitability
                                </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                            <div>
                               {last_level === 99  ? (
                                <Button onClick={() => onClickCompanyPendingTask(7)} className="button-dashboard-custom-submit">
                                Statement of Suitability
                                 </Button>
                            ) : (
                              <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                Statement of Suitability
                              </span>
                            )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item> 
                  
                  )} 

               
                  {last_level < 101 && (
                      <Accordion.Item eventKey="6">
                      <Accordion.Header>
                              <div className="dashboard-card-sub-heading"> 
                              <Button className="button-pending-dashboard">
                                Action Required
                              </Button>
                            &nbsp;&nbsp;Starlight Smart Reserve Model Portfolio Description
                              </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                          <div>
                             {last_level === 100  ? (
                              <Button onClick={() => onClickCompanyPendingTask(8)} className="button-dashboard-custom-submit">
                              Starlight Smart Reserve Model Portfolio Description
                               </Button>
                          ) : (
                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                              Starlight Smart Reserve Model Portfolio Description
                            </span>
                          )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item> 
                  )}
            </Accordion>
         </CardBody>
        </Card>
      </div>
  </>
      )
}
export default Organisation