import React, { useEffect, useState } from "react";
import { Card, CardBody } from "react-bootstrap";
import { Alert, Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getIbkr, transaction } from "app/entities/accounts/accounts.reducer";
import CommonToast from "app/modules/components/CommonToast";
import { getCompanyMembersList, ibkrportal, WithdrawalRequest, withdrawibkrportal } from "app/modules/profile/profile.reducer";
import { Helmet } from "react-helmet";
import Select from "react-select";

const Withdraw = () => {
  const dispatch = useAppDispatch();

  const [amount, setAmount] = useState("0.00");
  const [currency, setCurrency] = useState("EUR");
  const [depositDetails, setDepositDetails] = useState(null);
  const [ibkrNum, setIbkrNum] = useState(null);
  const [completeDocumentation, setCompleteDocumentation] = useState(true);
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const [selectedAuthoriser, setSelectedAuthoriser] = useState("");


  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | JSX.Element>('');
  const [secondToastMessage, setSecondToastMessage] = useState('');
  const [thirdToastMessage, setThirdToastMessage] = useState('');
  const [ErrorMessage, setErrorMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error' | 'warning'>('success');
  const [isLoading, setIsLoading] = useState(false);
  const { last_level } = useAppSelector(state => state.accounts.entity);
  const [CompanyMembers, setCompanyMembers] = useState<any>();
  const [selectedAuthorisers, setSelectedAuthorisers] = useState([]);


  const handleAuthoriserChange = (selectedOptions) => {
    if (!selectedOptions) {
      setSelectedAuthorisers([]);
      return;
    }
    // Check if "Not Applicable" is selected
    const hasNotApplicable = selectedOptions.some((option) => option.value === "not_applicable");

    if (hasNotApplicable) {
      // If "Not Applicable" is selected, set only that option
      setSelectedAuthorisers([{ value: "not_applicable", label: <div>Not Applicable</div> }]);
    } else {
      // Otherwise, remove "Not Applicable" from the selections
      const filteredOptions = selectedOptions.filter((option) => option.value !== "not_applicable");
      setSelectedAuthorisers(filteredOptions);
    }
  };

  const handleWithdrawalEmailChange = async () => {
    const hasNotApplicable = selectedAuthorisers.some((authoriser) => authoriser.value === "not_applicable");

    if (hasNotApplicable) {
      // If "Not Applicable" is selected, call handleTradeOrder instead
      handleTradeOrder();
      return;
    }

    if (selectedAuthorisers.length > 0) {
      const withdrawalPayload = {
        customerIds: selectedAuthorisers.map((authoriser) => authoriser.value),
        account_id: depositDetails?.sw_account_id || null,
        ibkr_account_number: depositDetails?.ibkr_account_number || null,
        amount: amount,
        transaction_type: "DEBIT",
        currency: currency,
        bank: "",
        is_organization: CompanyMembers?.organization
      };

      try {
        const response: any = await dispatch(WithdrawalRequest(withdrawalPayload));
        console.info("Withdrawal Request Success:", response);
        if (response) {
          setToastType('success');
          setToastVisible(true);
          setToastMessage(
            <Row className='text-center'>
              <div>Your Trade Redemption Order to redeem investments to allow you to make a<br/> cash withdrawal has been received.<br/>
We have sent a request to your co-authoriser to confirm this order and will notify you when confirmation has been received.<br/>
The Starlight Wealth Team
 </div>
            </Row>

          );
        }
      } catch (error) {
        console.error("Withdrawal Request Failed:", error);
      }
    } else {
      console.warn("No authorisers selected for withdrawal request.");
    }
  };



  const isTradeOrderDisabled =
    !amount || parseFloat(amount) <= 0 || !!ErrorMessage ||
    (CompanyMembers?.organization === true && selectedAuthorisers.length === 0);

  const formatCurrency = (amount: number): string => {
    return amount.toLocaleString('en-EN', {
      minimumFractionDigits: 0.00,
    });
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue) && numericValue >= 0) {
      setAmount(value);
      setErrorMessage('');
    } else if (value === '') {
      setAmount('0.00');
      setErrorMessage('');
    } else {
      setErrorMessage('Value cannot be negative');
    }
  };

  useEffect(() => {
    if (depositDetails?.sw_account_id) {
      dispatch(getCompanyMembersList(depositDetails?.sw_account_id))
        .then((response: any) => {
          setCompanyMembers(response.payload);
          console.info("response.payload", response.payload)
        })
        .catch((error: any) => {
          console.error('Failed to fetch company members:', error);
        });
    }
  }, [depositDetails?.sw_account_id, dispatch]);


  useEffect(() => {
    const fetchIbkrData = async () => {
      setIsLoading(true);
      try {
        const response = await dispatch(getIbkr());
        if (response?.payload) {
          setDepositDetails(response.payload);
          setIbkrNum(response.payload.ibkr_account_number)
        }
      } catch (error) {
        console.error('Failed to fetch IBKR data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchIbkrData();
  }, [dispatch]);

  const handleCurrencyChange = (e: any) => {
    setCurrency(e.target.value);
  };

  const handleTradeOrder = async () => {
    const payload = {
      account_id: depositDetails?.sw_account_id || null,
      ibkr_account_number: depositDetails?.ibkr_account_number || null,
      amount: amount,
      transaction_type: "DEBIT",
      currency: currency,
      bank: "",
      is_organization: CompanyMembers?.organization
    };

    try {
      const response: any = await dispatch(transaction(payload));

      if (response?.payload?.id) {
        setToastType('success');
        setToastVisible(true);
        setToastMessage(
          <Row className='text-center'>
            <div>Your Trade Redemption Order to redeem investments to allow you to make a cash withdrawal has been received.</div>
            <div className="pt-2">We will notify you when your <span style={{ color: 'black', fontWeight: 'bold' }}>Starlight Smart Account</span> is in 'settled cash funds' available to withdraw to your nominated bank account.</div>
            <div className="pt-2">The Starlight Wealth Team</div><br />
          </Row>

        );
      }
      else {
        const errorMsg = response?.payload?.message || '';
        const maxWithdrawalAmount = response?.payload?.maxWithdrawalAmount || 'N/A';

        if (errorMsg.includes("You can only withdraw up to 90% of your deposit amount")) {
          setToastType('warning');
          setToastVisible(true);

          const displayMessage = `Please note that you should always leave 1.00% of your account in cash funds following a withdrawal to allow for settlement of ongoing fees and charges on the Starlight Smart Account.`;
          const second_description = `Failure to do so may result in a forced liquidation of your investments as further described in our Terms of Business.`
          const third_description = `The recommended maximum withdrawal amount is ${maxWithdrawalAmount}`

          setToastMessage(displayMessage);
          setSecondToastMessage(second_description);
          setThirdToastMessage(third_description);

        } else {
          setToastType('error');
          setToastVisible(true);

          const displayMessage = errorMsg.includes("IBKR BadRequest")
            ? errorMsg.replace("IBKR BadRequest", "").trim()
            : 'Access denied';

          setToastMessage(displayMessage);
        }
      }
    }
    catch (error) {
      throw error
    }
  };

  const handleIBKRPortalLinkClick = async () => {
    try {
      const resultAction = await dispatch(withdrawibkrportal());

      if (resultAction && resultAction.payload) {
        const portalUrl = resultAction.payload;
        window.open(portalUrl, '_blank');
      } else {
        console.error('IBKR Portal URL not found');
      }
    } catch (error) {
      console.error('Error fetching IBKR Portal URL:', error);
    }
  };


  const directorOptions = [
    {
      value: "not_applicable",
      label: <div>Not Applicable</div>,
    },
    ...(CompanyMembers?.directors?.map((director) => ({
      value: director.customer_id,
      label: (
        <div>
          {director.name||director.firstname||""}
        </div>
      ),
    })) || []),
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#fff", // Light gray background
      border: "1px solid #8888881a",
      boxShadow: "none",
      minHeight: "36px", // Ensure enough height for selected items
      height: "36px",
      padding: "0px 4px", // Reduce padding inside input
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
      "&:hover": {
        borderColor: "#8888881a",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 4px", // Reduce padding inside the selected values container
      display: "flex",
      flexWrap: "nowrap", // Keep selected items in a single line
      overflowX: "auto", // Enable scrolling if there are too many selected items
      maxWidth: "100%",
    }),
    input: (base) => ({
      ...base,
      margin: "0px",
      padding: "0px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "36px", // Adjust height for dropdown indicators (caret)
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#607980",
      color: "#fff",
      borderRadius: "4px",
      padding: "2px 6px", // Adjust padding inside selected items
      fontSize: "12px",
      height: "24px", // Reduce height for compact look
      display: "flex",
      alignItems: "center",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#fff",
      padding: "2px 4px",
      fontSize: "12px",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#fff",
      padding: "0px 4px",
      "&:hover": {
        backgroundColor: "#607980",
      },
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? "#fff" : "#fff",
      color: isSelected ? "#607980" : "#333",
      "&:hover": {
        backgroundColor: "#607980",
        color: "#fff",
      },
      display: "flex",
      alignItems: "center",
      padding: "6px 10px",
      fontSize: "14px",
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px", // Reduce space between input and dropdown
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
  };


  return (
    <div className="col-12 pb-5">
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : toastType === 'warning' ? 'Warning' : 'Error'}
        description={toastMessage}
        second_description={secondToastMessage}
        third_description={thirdToastMessage}
        type={toastType}
        isAutoClose={false}
      />
      {isLoading ? (
        <div className="loader-container dashboard-loader-container">
          <div className="spinner-border text-primary custom-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        ibkrNum ? (
          <>
            <div className="row justify-content-center">
              <div className="pt-3 col-md-6 ">
                <div className="dashboard-card-heading">
                  Redeem Funds
                </div>
                <div className="row pt-1">
                  <div className="col dashboard-card-sub-heading align-content-center">
                    Withdrawal Amount:
                  </div>
                  <div className="col-8">
                    <Input
                      type="text"
                      name="withdrawal-amount"
                      id="withdrawal-amount"
                      className="dashboard-select-style"
                      placeholder="0.00"
                      value={amount !== '0.00' ? formatCurrency(parseFloat(amount)) : ''}
                      onChange={handleAmountChange} />

                    {ErrorMessage && <p className="text-danger">{ErrorMessage}</p>}
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col dashboard-card-sub-heading align-content-center">
                    Currency:
                  </div>
                  <div className="col-8">
                    <Input
                      type="select"
                      className="dashboard-select-style"
                      onChange={(e) => handleCurrencyChange(e)}
                    >
                      <option>EUR</option>
                      <option>USD</option>
                      <option>GBP</option>
                    </Input>
                  </div>
                </div>
                {CompanyMembers?.organization === true && (
                  <div className="row pt-3">
                    <div className="col dashboard-card-sub-heading align-content-center">
                      Joint Authorisers:
                    </div>

                    <div className="col-8">
                      <Select
                        options={directorOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        value={selectedAuthorisers}
                        onChange={handleAuthoriserChange}
                        getOptionLabel={(e) => e.label}
                        className="withdraw-users-select"
                        isSearchable={false}
                        styles={customStyles}
                      />
                    </div>

                  </div>
                )}
                <div className="row pt-5">
                  <Button className="button-account-custom-submit"
                    disabled={isTradeOrderDisabled}
                    onClick={CompanyMembers?.organization === true ? handleWithdrawalEmailChange : handleTradeOrder}
                  >Trade Redemption Order</Button>
                </div>
              </div>
            </div>
            <div className="pt-3 ">
              <Card className=" dashboard-custom-card">
                <CardBody>
                  <div className="dashboard-card-heading">
                    Important to know when making withdrawals:
                  </div>
                  <ol>
                    <li className="sub-content-dashboard pt-2">
                      You can only withdraw funds from a cleared cash balance in your <b> Starlight Smart Account</b> following the redemption or sale of investments in your <b>Starlight Smart Account</b> in accordance with the Terms of Business.
                      {/* <a className="link-invenstment-portal" target="_blank" href="./../../content/policies/starlight_wealth_terms_of_business.pdf">
                    Terms of Business
                  </a> . */}
                    </li>
                    <li className="sub-content-dashboard pt-2">
                      You must have your bank account details set up on your Investment Portal&nbsp;
                      {/* <span className="link-invenstment-portal" onClick={handleIBKRPortalLinkClick}> Investment Portal</span> */}
                      to receive the funds to be withdrawn. If these details have not been set up you will be unable to transfer funds out of your <b>Starlight Smart Account</b>.
                    </li>
                    <li className="sub-content-dashboard pt-2">
                      There is no charge for the first withdrawal (in each 30 day period); however, Interactive Brokers will charge withdrawal fees for any subsequent withdrawal (€1 per SEPA transfer) in the same period.
                    </li>
                  </ol>
                </CardBody>
              </Card>
            </div>
          </>
        ) : (
          last_level > 1 ? (
            <></>
          ) : (
            <Row className='justify-content-center'>
              <Card className='col-md-8 form-custom-card'>
                <CardBody>
                  <Col md="12" className='sub-content-dashboard'>
                    {/* <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
        Your application has been successfully submitted and is currently under review by our admin team. Once approved, it will be forwarded to IBKR for final approval, which may take an additional 2-3 days. You’ll receive an update via email once there’s any change in status. Thank you for your patience!        </Alert> */}
                    <div className="dashboard-card-sub-heading">Your Starlight Smart Account application is currently under review.</div>
                    <div className='pt-3'>We will be in touch by email if we need anything else and will let you know once your account has been approved.</div>
                    <div className='pt-3'>Thank you for your patience!</div>
                    <div className='pt-3 dashboard-card-sub-heading'>The Starlight Wealth Team</div>
                  </Col>
                </CardBody>
              </Card>
            </Row>
          )))}
      <Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/145768795.js" ></script>
      </Helmet>
    </div>
  )
}

export default Withdraw