import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import '../accounts/account.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useDispatch } from 'react-redux';
import { updateEntity, updateEntityProperty } from '../accounts/accounts.reducer';

interface CompanyPeopleProp {
  onFormComplete: (completed: boolean) => void;
}
export interface CompanyPeopleRef {
  syncCompanyPeopleWithServer: () => Promise<any>;
  saveCompanyPeopleWithServer: () => Promise<any>;
}
const CompanyPeopleForm: React.ForwardRefRenderFunction<CompanyPeopleRef, CompanyPeopleProp> = ({ onFormComplete }: CompanyPeopleProp, ref) => {

// const CompanyPeople = ({ checkboxSet, setCheckboxSet, numberOfForms, setNumberOfForms, numberOfSignedPeople, setNumberOfSignedPeople, setfirstName, key_person_details, setkey_person_details }) => {
  const dispatch = useAppDispatch();
  const [numberOfPeopleInCategory, setNumberOfPeopleInCategory] = useState('');
  
  // const [nameValues, setNameValues] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalPeople, setTotalPeople] = useState(1 || total);

    const [key_person_details, setkey_person_details] = useState({
      authorized_person_details: [
        {
          name: '',
          role: '',
          firstname: '',
          lastname: '',
          passport_number: '',
          company_email_address: '',
          mobile_number: '+353',
          home_address: '',
          account_number: '',
          customer_id: '',
          date_of_bith: '',
          issued_country: 'Ireland',
          passport_expiry_date: '',
          country_of_birth_and_citizen: 'Ireland',
          pps_number: '',
          country_of_tax_residency: 'Ireland',
          owns_more_25_of_company: false,
          source_of_person_wealth: '',
          passport_doc: '',
          address: {
            country: 'Ireland',
            state: 'Galway',
            city: '',
            postal_code: '',
            street: '',
          },
          proof_of_address: '',
  
        }
      ]
    });
      const [checkboxSet, setCheckboxSet] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
      });
      const [numberOfForms, setNumberOfForms] = useState(1);
      const [numberOfSignedPeople, setNumberOfSignedPeople] = useState(1)
      const [nameValues, setNameValues] = useState(''); 

  useImperativeHandle(ref, () => ({
    syncCompanyPeopleWithServer,
     saveCompanyPeopleWithServer
  }));

    const syncCompanyPeopleWithServer = (): Promise<any> => {
      return dispatch(updateEntity({ ...mainAccountsEntity,is_company_account: true,last_level: 3 }));
    };

    const saveCompanyPeopleWithServer = (): Promise<any> => {
      return dispatch(updateEntity({ ...mainAccountsEntity,  }));
    };
 

  useEffect(() => {

    setTotal(Number(sessionStorage.getItem('totalpeople')))

  }, []);

  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);

  useEffect(() => {
    const storedState = localStorage.getItem('key_person_details');
    // if (storedState) {
    //   setkey_person_details(JSON.parse(storedState));
    // }

    const storedNumberOfPeopleInCategory = localStorage.getItem('numberOfPeopleInCategory');
    if (storedNumberOfPeopleInCategory) {
      setNumberOfPeopleInCategory(storedNumberOfPeopleInCategory);
    }

  }, []);



  useEffect(() => {

    localStorage.setItem('key_person_details', JSON.stringify(mainAccountsEntity.company_details));
      const storedData = localStorage.getItem('key_person_details');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        setkey_person_details(parsedData);
      }
    }, []);
  useEffect(() => {
    localStorage.setItem('numberOfPeopleInCategory', numberOfPeopleInCategory);
  }, [numberOfPeopleInCategory]);


  useEffect(() => {
    const maxPeople = Math.max(numberOfSignedPeople,parseInt(numberOfPeopleInCategory, 10) || 0);
    setTotalPeople(maxPeople);

    sessionStorage.setItem('totalpeople', JSON.stringify(maxPeople));
  }, [ numberOfPeopleInCategory]);

  const handleSelectChange = e => {
    const value = e.target.value;
    setNumberOfSignedPeople(value);
    // setkey_person_details((key_person_details?.authorized_person_details?.[totalPeople]));
  };

  // const handleCategorySelectChange = e => {
  //   const value = e.target.value;
  //   setNumberOfPeopleInCategory(value);
  //   setNumberOfForms(parseInt(value, 10));
  //   setkey_person_details((key_person_details?.authorized_person_details[totalPeople]));
  // };
  const handleCategorySelectChange = e => {
    const value = e.target.value;
    const numPeople: any = parseInt(value, 10);

    // Update the number of people in category and forms
    setNumberOfPeopleInCategory(numPeople);
    setNumberOfForms(numPeople);

    // Update key_person_details while preserving existing data
    setkey_person_details(prevState => {
      const currentDetails = prevState?.authorized_person_details || [];
      const updatedDetails = [...currentDetails]; // Create a copy of current details
      const defaultPartialPerson = {
        firstname: '',
        lastname: '',
        role: '',
      };

      if (updatedDetails.length < numPeople) {
        // Add empty entries if needed
        for (let i = updatedDetails.length; i < numPeople; i++) {
          updatedDetails.push({ ...defaultPartialPerson, ...currentDetails[i] });
        }
      } else if (updatedDetails.length > numPeople) {
        // Remove extra entries if needed
        updatedDetails.length = numPeople;
      }

      return {
        ...prevState,
        authorized_person_details: updatedDetails
      };
    });
  };

  // const handleNameChange = (field, index, value) => {
  //   const updatedDetails = [...(key_person_details?.authorized_person_details || [{}])];
  //   updatedDetails[index] = { ...updatedDetails[index], [field]: value };
  //   setkey_person_details({
  //     ...key_person_details,
  //     authorized_person_details: updatedDetails,
  //   });

  // };

  const handleNameChange = (field, index, value) => {
    setkey_person_details((prevState) => {
      const updatedDetails =  [...(prevState?.authorized_person_details || [])];
      updatedDetails[index] = {
        ...updatedDetails[index], // Preserve existing fields
        [field]: value,          // Update only the specified field
      };
  
      return {
        ...prevState,
        authorized_person_details: updatedDetails,
      };
    });
  };

  const areRequiredFieldsEmpty = () => {
    if (key_person_details?.authorized_person_details) {
      onFormComplete(true);
    } else {
      onFormComplete(false);
    }
    }

    useEffect(() => {
      areRequiredFieldsEmpty();
    },[key_person_details])
    
  const handleCheckboxChange = (role, index, checked) => {
    // Ensure key_person_details is properly cloned
    const updatedDetails = JSON.parse(JSON.stringify(key_person_details?.authorized_person_details || [{}]));
  
    // Ensure the item at the index exists and is properly initialized
    if (!updatedDetails[index]) {
      updatedDetails[index] = { role: [] };
    }
  
    // Initialize the updatedCheckboxSet before usage
    const updatedCheckboxSet = { ...checkboxSet };
  
    // Get current roles or initialize as an empty array
    let currentRoles = updatedDetails[index].role || [];
  
    if (String(checked).toUpperCase() === 'YES') {
      if (!currentRoles.includes(role)) {
        currentRoles = [...currentRoles, role];
      }
  
      // Automatically select "Approval Authority" if "Company Authorized Officer" is selected
      if (role === 'AUTHORIZED OFFICER') {
        currentRoles = [...currentRoles, 'APPROVAL AUTHORITY'];
  
        // Deselect "Approval Authority" for any other previously selected "Company Authorized Officer"
        for (let i = 0; i < updatedDetails.length; i++) {
          if (i !== index && updatedDetails[i].role.includes('AUTHORIZED OFFICER')) {
            updatedDetails[i].role = updatedDetails[i].role.filter(r => r !== 'APPROVAL AUTHORITY');
            updatedDetails[i].role = updatedDetails[i].role.filter(r => r !== 'AUTHORIZED OFFICER');
            updatedCheckboxSet[`checkbox1_${i}`] = false;
            updatedCheckboxSet[`checkbox4_${i}`] = false;
          }
        }
      }
    } else {
      // Remove the role if unchecked
      currentRoles = currentRoles.filter(r => r !== role);
  
      // If "Company Authorized Officer" is deselected, also deselect "Approval Authority"
      if (role === 'AUTHORIZED OFFICER') {
        currentRoles = currentRoles.filter(r => r !== 'APPROVAL AUTHORITY');
      }
  
      // Prevent deselecting "Approval Authority" if "Company Authorized Officer" is still selected
      if (role === 'APPROVAL AUTHORITY' && currentRoles.includes('AUTHORIZED OFFICER')) {
        currentRoles = [...currentRoles, 'APPROVAL AUTHORITY'];
      }
    }
  
    // Update the roles for the person
    updatedDetails[index] = {
      ...updatedDetails[index],
      role: currentRoles,
    };
  
    // Update the checkbox states based on the current roles
    updatedCheckboxSet[`checkbox1_${index}`] = currentRoles.includes('AUTHORIZED OFFICER');
    updatedCheckboxSet[`checkbox2_${index}`] = currentRoles.includes('DIRECTOR');
    updatedCheckboxSet[`checkbox3_${index}`] = currentRoles.includes('BENIFICIAL OWNERS');
    updatedCheckboxSet[`checkbox4_${index}`] = currentRoles.includes('APPROVAL AUTHORITY');
  
    setkey_person_details(prevState => ({
      ...prevState,
      authorized_person_details: updatedDetails,
    }));
  
    setCheckboxSet(updatedCheckboxSet);
  
    dispatch(updateEntityProperty({
      ...mainAccountsEntity,
      company_details: {
        ...mainAccountsEntity.company_details,
        authorized_person_details: updatedDetails,
      },
    }));
  };
  
  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < totalPeople; i++) {
      const personRoles = key_person_details?.authorized_person_details?.[i]?.role || [];
      rows.push(
        <tr key={i}>
          {/* Other fields */}
          {/* ... */}
          <td style={{ padding: '10px' }}>
            <Input
              type="text"
              name="firstname"
              id="firstname"
              defaultValue={key_person_details?.authorized_person_details?.[i]?.firstname || ''}
              onChange={e => handleNameChange('firstname', i, e.target.value)}
              placeholder="First Name"
            />
          </td>
          <td style={{ padding: '10px' }}>
            <Input
              type="text"
              defaultValue={key_person_details?.authorized_person_details?.[i]?.lastname || ''}
              onChange={e => handleNameChange('lastname', i, e.target.value)}
              placeholder="Surname"
              className="form-control"
            />
          </td>
          <td style={{ padding: '10px', color: 'black' }}>
            <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input
                type="radio"
                id={`flexSwitchCheck1_${i}`}
                className='custom-radio-input'
                checked={personRoles.includes('AUTHORIZED OFFICER')}
                value={'Yes'}
                onChange={e => handleCheckboxChange('AUTHORIZED OFFICER', i, e.target.value)}
              />
              <label style={{paddingLeft: '10px'}}>Yes</label>
            </div>
            <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input
                type="radio"
                id={`flexSwitchCheck10_${i}`}
                className='custom-radio-input'
                checked={!personRoles.includes('AUTHORIZED OFFICER')}
                value={'No'}
                onChange={e => handleCheckboxChange('AUTHORIZED OFFICER', i, e.target.value)}
              />
              <label style={{paddingLeft: '10px'}}>No</label>
            </div>
          </td>
          <td style={{ padding: '10px', color: 'black' }}>
            <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input
                type="radio"
                id={`flexSwitchCheck2_${i}`}
                className='custom-radio-input'
                checked={personRoles.includes('DIRECTOR')}
                value={'Yes'}
                onChange={e => handleCheckboxChange('DIRECTOR', i, e.target.value)}
              />
              <label style={{paddingLeft: '10px'}}>Yes</label>
            </div>
            <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input
                type="radio"
                id={`flexSwitchCheck20_${i}`}
                className='custom-radio-input'
                checked={!personRoles.includes('DIRECTOR')}
                value={'No'}
                onChange={e => handleCheckboxChange('DIRECTOR', i, e.target.value)}
              />
              <label style={{paddingLeft: '10px'}}>No</label>
            </div>
          </td>
          <td style={{ padding: '10px', color: 'black' }}>
            <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input
                type="radio"
                id={`flexSwitchCheck3_${i}`}
                className='custom-radio-input'
                checked={personRoles.includes('BENIFICIAL OWNERS')}
                value={'Yes'}
                onChange={e => handleCheckboxChange('BENIFICIAL OWNERS', i, e.target.value)}
              />
              <label style={{paddingLeft: '10px'}}>Yes</label>
            </div>
            <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input
                type="radio"
                id={`flexSwitchCheck30_${i}`}
                className='custom-radio-input'
                checked={!personRoles.includes('BENIFICIAL OWNERS')}
                value={'No'}
                onChange={e => handleCheckboxChange('BENIFICIAL OWNERS', i, e.target.value)}
              />
              <label style={{paddingLeft: '10px'}}>No</label>
            </div>
          </td>
          <td style={{ padding: '10px', color: 'black' }}>
            <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input
                type="radio"
                id={`flexSwitchCheck4_${i}`}
                className='custom-radio-input'
                checked={personRoles.includes('APPROVAL AUTHORITY')}
                value={'Yes'}
                onChange={e => handleCheckboxChange('APPROVAL AUTHORITY', i, e.target.value)}
              />
              <label style={{paddingLeft: '10px'}}>Yes</label>
            </div>
            <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input
                type="radio"
                id={`flexSwitchCheck40_${i}`}
                className='custom-radio-input'
                checked={!personRoles.includes('APPROVAL AUTHORITY')}
                value={'No'}
                onChange={e => handleCheckboxChange('APPROVAL AUTHORITY', i, e.target.value)}
              />
              <label style={{paddingLeft: '10px'}}>No</label>
            </div>
          </td>
        </tr>
      );
    }
    return rows;
  };
  
  return (
      <Row>
        <Col md="12" className="form-heading-custom pt-3">
          Key People Associated With The Company
        </Col>

        <Col md="12" className="form-text-content pt-3">
            We need to get information from you on how you would like to manage your company account and key people associated with the company.
        </Col>

      <Col md="12" className='pt-3'>
        <FormGroup>
            <Label className="label-custom-form-input">
              <span>* </span>
              How many people must sign any instructions relating to the company account?
            </Label>
            <Input
              type="select"
              name="numberOfSignedPeople"
              id="numberOfSignedPeople"
              className="custom-from-input"
              value={numberOfSignedPeople}
              onChange={handleSelectChange}
            >
              <option value="1">1</option>
              <option value="2">2</option>
          </Input>
        </FormGroup>
      </Col>
      
        <Col md="12" style={{ lineHeight: '2' }}>
          <span>We are required by law to gather information on all of the below individuals involved in the company:</span>
          <ul>
            <li>
              Company Authorised Officer: the Company Officer who is completing this application and who has authority to sign agreements on
              behalf of the company for which the account is being opened. This person may or may not be a Director and / or Beneficial
              Owner
            </li>
            <li>Directors: as listed with the CRO (Companies Registration Office)</li>
            <li>
              Beneficial Owners: we are required to gather information on any individuals who own 15% or more of the company either directly
              or indirectly through other legal entities
            </li>
          </ul>
        </Col>
      <Col md="12">
        <FormGroup>
            <Label className="label-custom-form-input">
              <span>* </span>
              How many people fall into the above categories?
            </Label>

            <Input
              type="select"
              name="numberOfPeopleInCategory"
              id="numberOfPeopleInCategory"
              className="custom-from-input"
              value={numberOfPeopleInCategory}
              onChange={handleCategorySelectChange}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </Input>
        </FormGroup>
      </Col>
      

        <Col md="12 table-new-style" id='unique-table'>
  <table className="text-center table-custom-form">
    <thead className="new-form-table-row-one">
      <tr className='new-form-table-row-one'>
        <th style={{ padding: '10px', width: '16.6%' }}>First Name</th>
        <th style={{ padding: '10px', width: '16.6%' }}>Surname</th>
        <th style={{ padding: '10px', width: '16.6%' }}>Company Authorised Officer</th>
        <th style={{ padding: '10px', width: '16.6%' }}>Director</th>
        <th style={{ padding: '10px', width: '16.6%' }}>Beneficial Owner of 15%+ of the company</th>
        <th style={{ padding: '10px', width: '16.6%' }}>Has approval authority?</th>
      </tr>
    </thead>
    <tbody className="new-form-table-row-two">
      {renderRows()}
    </tbody>
  </table>
</Col>

      </Row>
  );
};

export default forwardRef(CompanyPeopleForm);
