import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card} from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import './open.scss';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getCustomerDetails } from './customer/customer.reducer';
export const Open = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const texts = [
    'Holding more than 200 deposits and short term securities of Sovereign Governments and rated banks',
    'No maximum investment amount,No fixed term, No exit penalties',
    'Available to individuals, companies, self-invested and broker advised pensions (ARFs, PRBs, PRSAs)',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);
  const handleClick = () => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      navigate('/accounts');
    } else {
      navigate('/register');
    }
  };
  const handleClickJoint = () => {
    dispatch(getCustomerDetails());
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      navigate('/company');
    } else {
      navigate('/register');
    }
  };

  // const handleClickJoint = (item) => {
  //   const accessToken = localStorage.getItem('jhi-authenticationToken');
  //   let path;
  //   if (accessToken) {
  //     if (item.title === "Open Company") {
  //       path = '/company';
  //     } else if (item.title === "Open Individual/Joint") {
  //       path = '/accounts';
  //     } else {
  //       path = item.path; // Assuming path is set directly for other cases
  //     }
  //   } else {
  //     navigate('/login');
  //   }
  // };

  const [smartInvest, setSmartInvest] = useState([])
  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/starlight-open-smart-invests?populate=*')
      .then(response => {
        const { data } = response;

        setSmartInvest(prev => ([...prev, ...data.data]));

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>
      {Array.isArray(smartInvest) && smartInvest.map((invest: any) => {
        return (
          <Row>

            <Col md="12" className="open-container d-flex justify-content-md-center flex-wrap ">
              <Card className="card-bg-style">
                <br />
                <span style={{ color: 'white', fontWeight: 'bold', fontSize: '45px' }}>{invest?.attributes?.heading}</span>

                <Row>
                  <Col md="6" sm="12" className="mt-4">
                    <Button className="open-btn-style" onClick={handleClick}>
                      Open Individual/Joint
                    </Button>
                  </Col>
                  <Col md="6" sm="12" className="mt-4">
                    <Button className="open-btn-style" onClick={handleClickJoint}>
                      Open Company
                    </Button>
                  </Col>
                  {/* {invest.attributes.buttons.map((item, index) => {
                    return (
                      <Col md="6" sm="12" className="mt-4" key={index}>
                        <Button className="open-btn-style" onClick={() => handleClickJoint(item)}>
                          {item.title}
                        </Button>
                      </Col>
                    );
                  })} */}
                </Row>

                <Row className="mt-4">
                  <h4>{invest?.attributes?.subHead}</h4>
                  {invest?.attributes?.content.map((item) => {
                    return (
                      <Col md="12">
                        <ul>
                          <li className="smart-sub-text">{item?.description}</li>

                        </ul>
                      </Col>
                    )
                  })}
                </Row>
                <Row className="mt-4">
                  <h4>{invest?.attributes?.title}</h4>

                  {invest?.attributes?.description?.map((item) => {
                    return (
                      <Col md="12" style={{ fontSize: '13px', fontFamily: 'Montserrat', marginTop: '20px' }}>
                        <p className="smart-sub-text">
                          {item?.description}
                        </p>
                      </Col>
                    )
                  })}
                </Row>

                <Row className="mt-4">
                  <Col
                    md="12"
                    style={{ fontSize: '18px', fontFamily: 'Montserrat', marginTop: '20px', fontStyle: 'italic', fontWeight: '300' }}
                  >
                    <p>
                      Starlight Wealth does not accept or hold client monies. Your Investment Amount is invested directly in the AAA and
                      Sovereign Money Market Funds comprising the relevant Starlight Smart Reserve Portfolio.
                    </p>

                    <p>
                      Funds and investments in your Starlight Smart Account are ‘client assets’ and are fully segregated from the assets of the
                      MIFID Executing Broker in accordance with Central Bank of Ireland regulatory rules.
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>

          </Row>
        )
      })}
    </>
  );
};

export default Open;
