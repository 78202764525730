import React, { useEffect } from 'react'
import DashboardHeader from '../dashboardheader/DashboardHeader'
import "./style.scss"
import { useNavigate } from 'react-router-dom';

import { Outlet } from 'react-router-dom'

const DashBoard = () => {
  const navigate = useNavigate()

  
  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (!accessToken) {
      navigate('/login');
    }
  }, [navigate]);
  
  return (
      <div className='dashboard-bg'>
          <DashboardHeader />
      {/* <div className="unknown-div" /> */}
      <div className="col-12 d-flex justify-content-center text-start">
        <div className="row width-full-page pb-2 relative-div-bg">
          <Outlet />
        </div>
      </div>
      
    </div>
  )
}

export default DashBoard