import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';

export const Logout = () => {
  const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      await dispatch(logout());
      sessionStorage.clear();
      localStorage.clear();

      if (logoutUrl) {
        window.location.href = logoutUrl;
      } else {
        navigate('/login', { replace: true });
      }
    };

    performLogout();
  }, []);

  return null;
};

export default Logout;
