import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import { depositConfig } from 'app/entities/accounts/accounts.reducer';
import React, { useEffect, useState } from 'react'

const FundingInstructions = ({depositDetails ,ibkr,modalturetochangetext}) => {  
  const copyToClipboard = (text) => {
    if (!text) return; // Don't attempt to copy if there's no text
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

 
  return (
      <>

      {modalturetochangetext ? (
          <div className='col-12 span-account-number sub-content-dashboard '>
              If you have already set up your <b> Starlight Smart Account </b> as a ‘quick’ or ‘regular’ payee for&nbsp;
              {depositDetails?.title} in your online banking portal, please click the ‘Deposit’ button below to continue and
              transfer funds in your online banking portal in the normal way.
          </div>
        ):(
          <div className='col-12 span-account-number sub-content-dashboard '>
          If you have already set up your <b> Starlight Smart Account </b> as a ‘quick’ or ‘regular’ payee for&nbsp;
          {depositDetails?.title} in your online banking portal, please click the 'Deposit' button to 
          transfer funds in your online banking portal in the normal way.
        </div>
        )}
      {modalturetochangetext ? (
        <div className='col-12 sub-content-dashboard pt-3'>
          If you have not already set up your <b>Starlight Smart Account</b> as a ‘quick’ or ‘regular’
          payee for&nbsp;{depositDetails?.title} in your online banking portal, please click the 'Deposit' button below
          and copy the funding details from this page into your online banking portal and save your&nbsp;
          <b>Starlight Smart Account</b> as a quick payee before transferring funds in your online banking
          portal in the normal way.
        </div>
      ):(
      <div className='col-12 sub-content-dashboard pt-3'>
              If you have not already set up your <b> Starlight Smart Account </b> as a ‘quick’ or ‘regular’ payee for&nbsp;
              {depositDetails?.title} in your online banking portal, please click the 'Deposit' button and copy the funding details
              from this page into your online banking portal and save your <b>Starlight Smart Account</b> as a quick
              payee before transferring funds in your online banking portal in the normal way.
      </div>
        )  }
          
      {modalturetochangetext ? (
          <div className='col-12 sub-content-dashboard pt-3'>
          <b> NB: Please make sure to include your 'Client Account Ref.' below as the payee
            Payment Reference so that JP Morgan can allocate funds to your client account
            without delay.  Failure to include this reference may result in delays or
            return of funds.</b>
        </div >

      ) :
        (
          <div className='col-12 sub-content-dashboard pt-3'>
          <b> NB: Please make sure to include your Account Number and Account Name as the payee Payment Reference
              so that JP Morgan can allocate funds to your client account without delay.  Failure to include this
              reference may result in delays or return of funds.</b>
          </div >
        )}
      <div className="col-12 dashboard-card-heading-two pt-3 d-flex">
        <div >
          <img src={depositDetails?.subtitle} alt="" className='img-flag-bank' />
        </div>
        <div className="img-div-flag-bank">
          {depositDetails?.title} Bank Transfer Instructions
        </div>
          </div>
          <div className="col-12 ">
          <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">
                Account name: 
              </div>
              <div className="col sub-content-dashboard">
                {/* {depositDetails?.bank_details["Account Title"]} */}
              <span>
              {depositDetails?.["title"]?.includes("EURO")
              ? depositDetails?.bank_details["Account Title"]
              : "XXX"}
              </span>
              <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(depositDetails?.bank_details?.["Account Title"])}
            title="Copy to clipboard"
          >
          <FontAwesomeIcon
            icon={faCopy}
            style={{  color: "#949494", cursor: 'pointer', transition: 'transform 0.2s' }}
            onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
            onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
          />
          </button>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">Address:</div>
              <div className="col sub-content-dashboard">
                {/* {depositDetails?.bank_details["Address"]} */}
              <span>
              {depositDetails?.["title"]?.includes("EURO")
              ? depositDetails?.bank_details["Address"]
              : "XXX"}
              </span>
              <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(depositDetails?.bank_details?.["Address"])}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
              icon={faCopy}
              style={{  color: "#949494",cursor: 'pointer', transition: 'transform 0.2s' }}
              onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
              onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">Bank:</div>
              <div className="col sub-content-dashboard">
                {/* {depositDetails?.bank_details["Beneficiary Bank"]} */}
              <span>
              {depositDetails?.["title"]?.includes("EURO")
              ? depositDetails?.bank_details["Beneficiary Bank"]
              : "XXX"}
              </span>
              <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(depositDetails?.bank_details?.["Beneficiary Bank"])}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
              icon={faCopy}
              style={{  color: "#949494", cursor: 'pointer', transition: 'transform 0.2s' }}
              onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
              onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>

              </div>
            </div>
            <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">IBAN:</div>
              <div className="col sub-content-dashboard">
              {/* {depositDetails?.bank_details?.["IBAN"] && (
              <>
                {depositDetails.bank_details["IBAN"].slice(0, -8)}
                <strong>{depositDetails.bank_details["IBAN"].slice(-8)}</strong>
              </>
              )} */}

              {depositDetails?.["title"]?.includes("EURO") ? (
                depositDetails?.bank_details?.["IBAN"] ? (
                  <>
                    {depositDetails.bank_details["IBAN"].slice(0, -8)}
                    <strong>{depositDetails.bank_details["IBAN"].slice(-8)}</strong>
                  </>
                ) : null
              ) : (
                "XXX"
              )}
              <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(depositDetails?.bank_details?.["IBAN"])}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
              icon={faCopy}
              style={{  color: "#949494" ,cursor: 'pointer', transition: 'transform 0.2s' }}
              onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
              onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">BIC:</div>
              <div className="col sub-content-dashboard">
            {/* {depositDetails?.bank_details?.["BIC"]} */}
            <span>
              {depositDetails?.["title"]?.includes("EURO")
              ? depositDetails?.bank_details["BIC"]
              : "XXX"}
              </span>
            <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(depositDetails?.bank_details?.["BIC"])}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
              icon={faCopy}
              style={{  color: "#949494" ,cursor: 'pointer', transition: 'transform 0.2s' }}
              onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
              onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>

              </div>
            </div>
            {ibkr!==null &&(<div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">Client Account Ref:</div>
              <div className="col sub-content-dashboard">
            {ibkr?.ibkr_account_number}
            <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(ibkr?.ibkr_account_number)}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
              icon={faCopy}
              style={{  color: "#949494" ,cursor: 'pointer', transition: 'transform 0.2s' }}
              onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
              onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>

              </div>
            </div>)}
          </div>
           
    </>
  )
}

export default FundingInstructions