import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ASC } from 'app/shared/util/pagination.constants';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICustomer, defaultValue } from 'app/shared/model/customer.model';
import { IAccounts } from 'app/shared/model/accounts.model';

const initialState: EntityState<ICustomer> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,

  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/customers';
const apipendingUrl = `${SERVER_API_URL}api/accounts`;
const customerUrl = 'api/customer/details';
const pendingUrl = `${SERVER_API_URL}api/accounts/pendings `;
const account_type =`${SERVER_API_URL}api/accounts/details`;
const pendinglist = `${SERVER_API_URL}api/accounts/pending-list`;
const companymemberslist = `${SERVER_API_URL}api/accounts/list-company-members`;
const withdrawalrequest = `${SERVER_API_URL}api/accounts/company/withdrawal-confirmation`;
const pendingtask = `${SERVER_API_URL}api/accounts/pending-task/organization`;
const Autherisationwithdraw = `${SERVER_API_URL}api/accounts/confirm/withdrawal`;


export const getCompletedAccountType = createAsyncThunk('customer/account/type', async ({ sort }: IQueryParams) => {
  const requestUrl = account_type;
  return axios.get<any>(requestUrl);
});

export const getEntities = createAsyncThunk('customer/fetch_entity_list', async ({ sort }: IQueryParams) => {
  const requestUrl = pendingUrl;
  return axios.get<any>(requestUrl);
});

export const getPendingTask = createAsyncThunk('customer/pending', async ({ sort }: IQueryParams) => {
  const requestUrl = pendingUrl;
  return axios.get<any>(requestUrl);
});

export const getPendingList = createAsyncThunk('customer/pendinglist', async ({ sort }: IQueryParams) => {
  const requestUrl = pendinglist;
  return axios.get<any>(requestUrl);
});

export const getPendingTaskOrganisation = createAsyncThunk('customer/pendingTask_Organisation', async ({ sort }: IQueryParams) => {
  const requestUrl = pendingtask;
  return axios.get<any>(requestUrl);
});

export const getAutherisationWithdraw = createAsyncThunk('accounts/Autherisation_withdraw', async (accountId: string) => {
  try {
    const response = await axios.get(`${Autherisationwithdraw}/${accountId}`);
    return response?.data;
  } catch (error) {
    throw new Error('Failed to fetch country list');
  }
});

export const getCompanyMembersList = createAsyncThunk('accounts/fetch_company_members_list', async (accountId: string) => {
  try {
    const response = await axios.get(`${companymemberslist}/${accountId}`);
    return response?.data;
  } catch (error) {
    throw new Error('Failed to fetch country list');
  }
});

export const WithdrawalRequest = createAsyncThunk(
  'dashboard/withdrawal_request',
  async (payload: any, thunkAPI) => {
    try {
      const response = await axios.post(withdrawalrequest, payload);
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const getCustomerDetails = createAsyncThunk('customer/fetch_details', async () => {
  const response = await axios.get<ICustomer[]>(SERVER_API_URL + customerUrl);
 

  return response.data;
});

export const ibkrportal = createAsyncThunk(
  'accounts/ibkr_portal',
  async () => {
    const requestUrl = `${SERVER_API_URL}api/ibkr/sso`;
    try {
      const response = await axios.get(requestUrl);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching IBKR SSO data: ${error.message}`);
    }
  }
);
export const withdrawibkrportal = createAsyncThunk(
  'accounts/withdraw_ibkr_portal',
  async () => {
    const requestUrl = `${SERVER_API_URL}api/ibkr/sso?action=TransferFunds`;
    try {
      const response = await axios.get(requestUrl);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching IBKR SSO data: ${error.message}`);
    }
  }
);

export const getEntity = createAsyncThunk(
  'customer/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICustomer>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'customer/create_entity',
  async (entity: ICustomer, thunkAPI) => {
    const result = await axios.post<ICustomer>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getPendingEntity = createAsyncThunk(
  'accounts/create_entity', async () => {
      const result = `${apipendingUrl}`;
      return axios.get<IAccounts>(result);
  }
);

export const updateEntity = createAsyncThunk(
  'customer/update_entity',
  async (entity: ICustomer, thunkAPI) => {
    const result = await axios.put<ICustomer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'customer/partial_update_entity',
  async (entity: ICustomer, thunkAPI) => {
    const result = await axios.patch<ICustomer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'customer/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICustomer>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const CustomerSlice = createEntitySlice({
  name: 'customer',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = { ...action.payload.data, last_level: state.entity.last_level };
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getCustomerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })

      .addCase(getPendingTask.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getPendingTask.fulfilled, (state, action) => {
        state.loading = false;

      })
      .addCase(getPendingTask.rejected, (state, action) => {
        state.loading = false;
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data.sort((a, b) => {
            if (!action.meta?.arg?.sort) {
              return 1;
            }
            const order = action.meta.arg.sort.split(',')[1];
            const predicate = action.meta.arg.sort.split(',')[0];
            return order === ASC ? (a[predicate] < b[predicate] ? -1 : 1) : b[predicate] < a[predicate] ? -1 : 1;
          }),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CustomerSlice.actions;

export default CustomerSlice.reducer;
